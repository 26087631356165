import React, { useState, useEffect } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { handleLoginSubmit } from '../../services/LoginService'; // Importez la fonction handleLoginSubmit
import { handleSignupSubmit } from '../../services/SignUpService';
import { jwtDecode } from "jwt-decode";

function UserAuthentificate({ nextStep, prevStep, selectedTheme }) {
  const [loginData, setLoginData] = useState({ username: '', password: '' });
  const [showLoginError, setShowLoginError] = useState(false);
  const [showSignupForm] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const history = useHistory();
  const [signupData, setSignupData] = useState({
    username: '',
    password: '',
    firstName: '',
    lastName: '',
    address: '',
    dateOfBirth: ''
  });
  const [showSignupError, setShowSignupError] = useState(false);
  const [username, setUsername] = useState(null);

  const handleLogout = () => {
    localStorage.removeItem('token');
    history.push('/CustomWebsite');
    window.location.reload()
  };

  const handleLoginSubmitWrapper = async (event) => {
    event.preventDefault();
    await handleLoginSubmit(loginData, selectedTheme, setToken, () => nextStep(selectedTheme, loginData.username), setShowLoginError);
    setUsername(loginData.username)
  };
  
  const handleSignupSubmitWrapper = async (event) => {
    event.preventDefault();
    await handleSignupSubmit(
      signupData, // Utilisez directement signupData
      selectedTheme,
      setToken,
      () => nextStep(selectedTheme, signupData.username),
      setShowSignupError
    );
    setUsername(signupData.username)
  };

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      setUsername(decodedToken.username);
    }
  }, [token]);
  

  return (
    <>
    {!token && (
      <div className="row">
          <Form onSubmit={handleLoginSubmitWrapper} style={{ display: showSignupForm ? 'none' : 'block', width: '300px' }}>
            <Form.Group controlId="loginEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Entrez votre email"
                value={loginData.username}
                onChange={(e) => setLoginData({ ...loginData, username: e.target.value })}
                autoComplete="username"
              />
            </Form.Group>

            <Form.Group controlId="loginPassword">
              <Form.Label>Mot de passe</Form.Label>
              <Form.Control
                type="password"
                placeholder="Mot de passe"
                value={loginData.password}
                onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
                autoComplete="current-password"

              />
            </Form.Group>
            {showLoginError && <Alert variant="danger">Identifiants incorrects</Alert>}
            <div style={{ marginTop: '10px' }}>
              <Button className="buy-btn" variant="primary" type="submit">
                Se connecter
              </Button>
            </div>
          </Form>

          <Form onSubmit={handleSignupSubmitWrapper}>
            <Form.Group controlId="signupEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Entrez votre email"
                value={signupData.username}
                onChange={(e) => setSignupData({ ...signupData, username: e.target.value })}
                required
                autoComplete="username"
              />
            </Form.Group>

            <Form.Group controlId="signupPassword">
              <Form.Label>Mot de passe</Form.Label>
              <Form.Control
                type="password"
                placeholder="Mot de passe"
                value={signupData.password}
                onChange={(e) => setSignupData({ ...signupData, password: e.target.value })}
                required
                autoComplete="current-password"
              />
            </Form.Group>

            <Form.Group controlId="signupFirstName">
              <Form.Label>Prénom</Form.Label>
              <Form.Control
                type="text"
                placeholder="Entrez votre prénom"
                value={signupData.firstName}
                onChange={(e) => setSignupData({ ...signupData, firstName: e.target.value })}
                required
              />
            </Form.Group>

            <Form.Group controlId="signupLastName">
              <Form.Label>Nom</Form.Label>
              <Form.Control
                type="text"
                placeholder="Entrez votre nom"
                value={signupData.lastName}
                onChange={(e) => setSignupData({ ...signupData, lastName: e.target.value })}
                required
              />
            </Form.Group>

            <Form.Group controlId="signupAddress">
              <Form.Label>Adresse</Form.Label>
              <Form.Control
                type="text"
                placeholder="Entrez votre adresse"
                value={signupData.address}
                onChange={(e) => setSignupData({ ...signupData, address: e.target.value })}
                required
              />
            </Form.Group>

            <Form.Group controlId="signupDateOfBirth">
              <Form.Label>Date de naissance</Form.Label>
              <Form.Control
                type="date"
                placeholder="Entrez votre date de naissance"
                value={signupData.dateOfBirth}
                onChange={(e) => setSignupData({ ...signupData, dateOfBirth: e.target.value })}
                required
              />
            </Form.Group>

            {showSignupError && <Alert variant="danger">Erreur lors de la création du compte</Alert>}
            <Button className="buy-btn" variant="success" type="submit">
              S'inscrire
            </Button>
          </Form>
          
          <Button className="buy-btn" variant="secondary" onClick={prevStep}>Précédent</Button>{' '}

      </div>


    )}

    {token && (
      <div>

        Vous êtes authentifié, souhaitez-vous être déconnecté ?
        <Button className="buy-btn" variant="warning" onClick={handleLogout}>Déconnexion</Button>
        <Button className="buy-btn" variant="secondary" onClick={() => prevStep()}>Précédent</Button>{' '}
        <Button className="buy-btn" variant="primary" onClick={() => nextStep(selectedTheme, username)}>Suivant</Button>
      </div>
    )}
    </>
);
}

export default UserAuthentificate;
