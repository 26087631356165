import React, { useEffect } from 'react';
import Header from './pages/basic/Header';
import { BrowserRouter as Router, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import Home from './pages/Home'; 
import MySpace from './pages/myspace/MySpace';
import Contact from './pages/Contact';
import AbonmentReturn from './pages/AbonmentReturn';
import LoginPage from './pages/auth/LoginPage';
import CustomWebsite from './pages/customWebsite/processAddSite';
import NotFound from './pages/NotFound';
import CookiePopup from './pages/CookiePopup';
import SignupSuccess from './pages/auth/SignupSucess';
import SignUpPage from './pages/auth/SignUpPage';
import Pricing from './pages/pricing';
import Experts from './pages/Experts';

import { AuthProvider, useAuth } from './context/AuthContext';

import ReactGA from 'react-ga4'; // Importez react-ga4

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './assets/vendor/bootstrap-icons/bootstrap-icons.css';
import './assets/vendor/boxicons/css/boxicons.min.css';
import './assets/vendor/glightbox/css/glightbox.min.css';
import './assets/vendor/remixicon/remixicon.css';

import './assets/css/style.css';
import './assets/css/footer.css';
import './assets/css/header.css';
import './assets/css/navbar.css';
import './assets/css/mobile.css';
import './assets/css/contact.css';
import './assets/css/home-header.css';
import './assets/css/login.css';
import './assets/css/pricing.css';
import './assets/css/services.css';
import './assets/css/notfound.css';
import './assets/css/abonmentReturn.css';
import './assets/css/carousel.css';
import './assets/css/consulting.css';
import './assets/css/signup.css';

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <Header />
        <RouteChangeHandler /> {/* Gestionnaire de changement de route */}
        <div>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/myspace" component={MySpace} />
            <Route path="/contact" component={Contact} />
            <Route path="/loginpage" component={LoginPage} />
            <Route path="/customwebsite" component={CustomWebsite} />
            <Route path="/abonmentreturn" component={AbonmentReturn} />
            <Route path="/signupsuccess" component={SignupSuccess} />
            <Route path="/signuppage" component={SignUpPage} />
            <Route path="/pricing" component={Pricing} />
            <Route path="/experts" component={Experts} />
            <Route component={NotFound} />
          </Switch>
        </div>
        <CookiePopup />
      </AuthProvider>
    </Router>
  );
};

const RouteChangeHandler = () => {
  const { token, setToken, checkTokenValidity } = useAuth();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize('G-SWYQVVPHYP');
    // Suivi des pages vues
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });

    const handleInvalidToken = () => {
      setToken('');
      localStorage.removeItem('token');
      history.push('/');
    };

    checkTokenValidity(token, handleInvalidToken);
  }, [location.pathname, location.search, checkTokenValidity, history, setToken, token]);

  return null;
};

export default App;
