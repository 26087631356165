import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SignIn from './SignIn';
import Navbar from './../basic/Navbar';

function Login() {
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      window.location.reload();
      history.push('/MySpace');
    }
  }, [history]);

  return (
    <>
    
    <Navbar/>

    <section id="login" className="login custom-login">
        <div className="container">
          <div className="row">
            <div className="col"></div>
            <div className="flex col-6">

              <SignIn />
              </div>
              <div className="col"></div>
            </div>
        </div>

    </section>
    </>
  );
}

export default Login;
