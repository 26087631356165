import React, { useState, useEffect } from 'react';

const CookiePopup = () => {
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    // Vérifier si l'utilisateur a déjà accepté les cookies
    // Cela peut être stocké dans localStorage, par exemple
    const isAccepted = localStorage.getItem('cookiesAccepted');
    if (isAccepted) {
      setAccepted(true);
    }
  }, []);

  const handleAccept = () => {
    // Stocker l'acceptation des cookies dans localStorage ou un autre mécanisme de stockage
    localStorage.setItem('cookiesAccepted', 'true');
    setAccepted(true);
  };

  const handleDecline = () => {
    // Traitement pour les utilisateurs qui refusent les cookies
    // Par exemple, vous pouvez afficher un message indiquant que certaines fonctionnalités seront désactivées
    alert("Vous avez refusé l'acceptation des cookies.");
  };

  if (accepted) {
    return null; // Ne pas afficher la pop-up si les cookies ont déjà été acceptés
  }

  return (
    <div className="cookie-popup">
      <p>Ce site utilise des cookies pour améliorer votre expérience utilisateur.</p>
      <div>
        <button onClick={handleAccept}>Accepter</button>
        <button onClick={handleDecline}>Refuser</button>
      </div>
    </div>
  );
};

export default CookiePopup;
