import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <section id="footer" style={{ marginLeft: '180px' }}>

      <div className="row">

        <div className="col-lg-4 col-md-6 footer-contact">
            <h3>SISENT</h3>
            <p>
            3 Rue Sancho Panca <br />
            93160 Noisy-Le-Grand<br />
            France
            <br />
            <strong>Email:</strong> contact@sisent.fr
            <br />
            &copy; Copyright <strong><span>SISENT</span></strong>. All Rights Reserved

            </p>
        </div>

        <div className="col-lg-3 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
            <li><i className="bx bx-chevron-right"></i> <Link to="/pricing">Tarifs</Link></li>
            <li><i className="bx bx-chevron-right"></i> <Link to="/customwebsite">Themes</Link></li>
            <li><i className="bx bx-chevron-right"></i> <Link to="/customwebsite">Wordpress</Link></li>
            <li><i className="bx bx-chevron-right"></i> <Link to="/experts">Experts</Link></li>
            </ul>
        </div>

        <div className="col-lg-3 col-md-6 footer-links">
            <h4>Our Social Networks</h4>
            <p>Cras fermentum odio eu feugiat lide par naso tierra videa magna derita valies</p>
            <div className="social-links mt-3">
                <a href="/" className="twitter"><i className="bx bxl-twitter"></i></a>
                <a href="/" className="facebook"><i className="bx bxl-facebook"></i></a>
                <a href="/" className="instagram"><i className="bx bxl-instagram"></i></a>
                <a href="/" className="google-plus"><i className="bx bxl-skype"></i></a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/sisent" className="linkedin"><i className="bx bxl-linkedin"></i></a>
            </div>
            <br></br>
            <br></br>
            <br></br>
        </div>

      </div>
    </section>
  );
};

export default Footer;
