import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const history = useHistory();

  const checkTokenValidity = useCallback(async (token) => {
    const handleInvalidToken = () => {
      setToken('');
      localStorage.removeItem('token');
      history.push('/');
    };

    try {
      if (token) {
        const apiUrl = process.env.REACT_APP_API_AUTH_URL || 'http://localhost:3100/api';
        const response = await axios.get(`${apiUrl}/auth/protected`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.status !== 200) {
          handleInvalidToken();
        }
      }
    } catch (error) {
      console.error('Erreur lors de la vérification du token : ', error);
      handleInvalidToken();
    }
  }, [history]);

  useEffect(() => {
    const tokenCheckInterval = setInterval(() => {
      checkTokenValidity(token);
    }, 300000); // Vérifiez la validité du token toutes les 5 minutes

    return () => clearInterval(tokenCheckInterval);
  }, [token, checkTokenValidity]);

  const setTokenAndLocalStorage = (newToken) => {
    setToken(newToken);
    localStorage.setItem('token', newToken);
  };

  return (
    <AuthContext.Provider value={{ token, setToken: setTokenAndLocalStorage, checkTokenValidity }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
