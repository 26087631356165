import axios from 'axios';

export async function handleLoginSubmit(loginData, selectedTheme, setToken, nextStep, setShowLoginError) {
  try {
    const REACT_APP_API_AUTH_URL = process.env.REACT_APP_API_AUTH_URL;
    const response = await axios.post(`${REACT_APP_API_AUTH_URL}/auth/login`, loginData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = response.data;
    if (response.status === 200) {
      setToken(data.token);
      localStorage.setItem('token', data.token); // Stocker le token dans le localStorage
      nextStep(selectedTheme); // Passer à la prochaine étape si la connexion réussit
    } else {
      setShowLoginError(true);
    }
  } catch (error) {
    console.error('Error:', error);
    setShowLoginError(true); // Afficher l'erreur en cas d'exception
  }
}
