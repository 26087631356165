import React from 'react';
import TariffGrid from './TariffGrid';

function Pricing() {

  return (

    <TariffGrid  />

  );
}

export default Pricing;
