import React from "react";
import { Link } from "react-router-dom";
import TariffGrid from "./TariffGrid";
import Footer from "./basic/Footer";
import Navbar from "./basic/Navbar";
import HeaderWithPen from "./HeaderWithPen";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

import ImgHome1 from "../assets/img/imgHome/homeImg.png";
import ImgHome2 from "../assets/img/imgHome/homeImg2.png";
import ImgHome3 from "../assets/img/imgHome/homeImg3.png";
import ImgHome4 from "../assets/img/imgHome/homeImg4.png";
import ImgHome5 from "../assets/img/imgHome/homeImg5.png";
import ImgHome6 from "../assets/img/imgHome/homeImg6.png";
import ImgHome7 from "../assets/img/imgHome/homeImg7.png";
import ImgHome8 from "../assets/img/imgHome/homeImg8.png";
import ImgHome9 from "../assets/img/imgHome/homeImg9.png";

function Home() {
  return (
    <>
      <Navbar />
      <HeaderWithPen />

      <main className="mb-4 pt-14">
        <section id="image-carousel-container-txt ">
          <div className="container">
            <div className="row">
              <div>
                <h1>ESSENTIEL</h1>
                <div className="orange-bar"></div>
                <p>
                  Bénéficiez d’une plateforme intuitive pour concevoir un site
                  internet à votre image, avec une multitude de thèmes et de
                  fonctionnalités avancées
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="image-carousel-container-essentiel">
          <Swiper
            style={{ direction: "rtl" }}
            spaceBetween={20}
            freeMode={true}
            loop={true}
            centeredSlides={true}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
            }}
            speed={3500}
            modules={[Pagination, Navigation, Autoplay]}
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
              1280: {
                slidesPerView: 4,
              },
              1440: {
                slidesPerView: 5,
              },
            }}
            className="mySwiper swiper-wrapper"
          >
            <SwiperSlide>
              <img src={ImgHome1} alt="First slide" />
              <div className="hover-content">
                <Link to="/" className="hover-button">
                  Accueil
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <img src={ImgHome2} alt="Second slide" />
              <div className="hover-content">
                <Link to="/" className="hover-button">
                  Accueil
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <img src={ImgHome3} alt="Third slide" />
              <div className="hover-content">
                <Link to="/" className="hover-button">
                  Accueil
                </Link>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <img src={ImgHome1} alt="First slide" />
              <div className="hover-content">
                <Link to="/" className="hover-button">
                  Accueil
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <img src={ImgHome2} alt="Second slide" />
              <div className="hover-content">
                <Link to="/" className="hover-button">
                  Accueil
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <img src={ImgHome3} alt="Third slide" />
              <div className="hover-content">
                <Link to="/" className="hover-button">
                  Accueil
                </Link>
              </div>
            </SwiperSlide>
          </Swiper>
        </section>

        <section id="image-carousel-container-txt">
          <div className="container">
            <div className="row">
              <div id="carousel-wordpress-txt" style={{ textAlign: "right" }}>
                <h1>WORDPRESS</h1>
                <div className="orange-bar-right"></div>
                <p>
                  Obtenez un site WordPress fonctionnel et hébergé, installé par
                  nos experts en un clin d'œil.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="image-carousel-container-wordpress">
          <Swiper
            style={{ direction: "rtl" }}
            spaceBetween={20}
            freeMode={true}
            loop={true}
            centeredSlides={true}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
            }}
            speed={3500}
            modules={[Pagination, Navigation, Autoplay]}
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
              1280: {
                slidesPerView: 4,
              },
              1440: {
                slidesPerView: 5,
              },
            }}
            className="mySwiper swiper-wrapper"
          >
            <SwiperSlide>
              <img src={ImgHome4} alt="First slide" />
              <div className="hover-content">
                <Link to="/" className="hover-button">
                  Accueil
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <img src={ImgHome5} alt="Second slide" />
              <div className="hover-content">
                <Link to="/" className="hover-button">
                  Accueil
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <img src={ImgHome6} alt="Third slide" />
              <div className="hover-content">
                <Link to="/" className="hover-button">
                  Accueil
                </Link>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <img src={ImgHome4} alt="First slide" />
              <div className="hover-content">
                <Link to="/" className="hover-button">
                  Accueil
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <img src={ImgHome5} alt="Second slide" />
              <div className="hover-content">
                <Link to="/" className="hover-button">
                  Accueil
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <img src={ImgHome6} alt="Third slide" />
              <div className="hover-content">
                <Link to="/" className="hover-button">
                  Accueil
                </Link>
              </div>
            </SwiperSlide>
          </Swiper>
        </section>

        <section id="image-carousel-container-txt">
          <div className="container">
            <div className="row">
              <div>
                <h2>PREMIUM</h2>
                <div className="orange-bar"></div>
                <p>
                  Pour un site web parfaitement ajusté à vos besoins, demandez
                  un devis et consultez nos experts dès maintenant.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="image-carousel-container-premium">
          <Swiper
            style={{ direction: "rtl" }}
            spaceBetween={20}
            freeMode={true}
            loop={true}
            centeredSlides={true}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
            }}
            speed={3500}
            modules={[Pagination, Navigation, Autoplay]}
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
              1280: {
                slidesPerView: 4,
              },
              1440: {
                slidesPerView: 5,
              },
            }}
            className="mySwiper swiper-wrapper"
          >
            <SwiperSlide>
              <img src={ImgHome7} alt="First slide" />
              <div className="hover-content">
                <Link to="/" className="hover-button">
                  Accueil
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <img src={ImgHome8} alt="Second slide" />
              <div className="hover-content">
                <Link to="/" className="hover-button">
                  Accueil
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <img src={ImgHome9} alt="Third slide" />
              <div className="hover-content">
                <Link to="/" className="hover-button">
                  Accueil
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <img src={ImgHome7} alt="First slide" />
              <div className="hover-content">
                <Link to="/" className="hover-button">
                  Accueil
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <img src={ImgHome8} alt="Second slide" />
              <div className="hover-content">
                <Link to="/" className="hover-button">
                  Accueil
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <img src={ImgHome9} alt="Third slide" />
              <div className="hover-content">
                <Link to="/" className="hover-button">
                  Accueil
                </Link>
              </div>
            </SwiperSlide>
          </Swiper>
        </section>

        <TariffGrid />
      </main>
      <Footer />
    </>
  );
}
export default Home;
