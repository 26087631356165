import React, { useState, useEffect } from 'react';
import { jwtDecode } from "jwt-decode";

function MySpaceDocument() {
    const [userRole, setUserRole] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = jwtDecode(token);
            setUserRole(decodedToken.role);
        }
    }, []);

    return (
        <section className="section dashboard">
            <div className="row">
                <div className="col-lg-8">
                    <div className="row">

                        <div className="col-xxl-4 col-md-6">
                            <div className="card info-card sales-card">
                                <div className="filter">
                                    <button className="icon" data-bs-toggle="dropdown">
                                        <i className="bi bi-three-dots"></i>
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                        <li className="dropdown-header text-start">
                                            <h6>Filter</h6>
                                        </li>
                                        <li><button className="dropdown-item">Today</button></li>
                                        <li><button className="dropdown-item">This Month</button></li>
                                        <li><button className="dropdown-item">This Year</button></li>
                                    </ul>
                                </div>

                                <div className="card-body">
                                    <h5 className="card-title">Documents <span>| Today</span></h5>
                                    <div className="d-flex align-items-center">
                                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                            <i className="bi bi-cart"></i>
                                        </div>
                                        <div className="ps-3">
                                            <h6>145</h6>
                                            {userRole}
                                            <span className="text-success small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">increase</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xxl-4 col-md-6">
                            <div className="card info-card revenue-card">
                                <div className="filter">
                                    <button className="icon" data-bs-toggle="dropdown">
                                        <i className="bi bi-three-dots"></i>
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                        <li className="dropdown-header text-start">
                                            <h6>Filter</h6>
                                        </li>
                                        <li><button className="dropdown-item">Today</button></li>
                                        <li><button className="dropdown-item">This Month</button></li>
                                        <li><button className="dropdown-item">This Year</button></li>
                                    </ul>
                                </div>

                                <div className="card-body">
                                    <h5 className="card-title">Revenue <span>| This Month</span></h5>
                                    <div className="d-flex align-items-center">
                                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                            <i className="bi bi-currency-dollar"></i>
                                        </div>
                                        <div className="ps-3">
                                            <h6>$3,264</h6>
                                            <span className="text-success small pt-1 fw-bold">8%</span> <span className="text-muted small pt-2 ps-1">increase</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xxl-4 col-xl-12">
                            <div className="card info-card customers-card">
                                <div className="filter">
                                    <button className="icon" data-bs-toggle="dropdown">
                                        <i className="bi bi-three-dots"></i>
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                        <li className="dropdown-header text-start">
                                            <h6>Filter</h6>
                                        </li>
                                        <li><button className="dropdown-item">Today</button></li>
                                        <li><button className="dropdown-item">This Month</button></li>
                                        <li><button className="dropdown-item">This Year</button></li>
                                    </ul>
                                </div>

                                <div className="card-body">
                                    <h5 className="card-title">Customers <span>| This Year</span></h5>
                                    <div className="d-flex align-items-center">
                                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                            <i className="bi bi-people"></i>
                                        </div>
                                        <div className="ps-3">
                                            <h6>1244</h6>
                                            <span className="text-danger small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">decrease</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="card">
                                <div className="filter">
                                    <button className="icon" data-bs-toggle="dropdown">
                                        <i className="bi bi-three-dots"></i>
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                        <li className="dropdown-header text-start">
                                            <h6>Filter</h6>
                                        </li>
                                        <li><button className="dropdown-item">Today</button></li>
                                        <li><button className="dropdown-item">This Month</button></li>
                                        <li><button className="dropdown-item">This Year</button></li>
                                    </ul>
                                </div>

                                <div className="card-body">
                                    <h5 className="card-title">Reports <span>/Today</span></h5>
                                    <div id="reportsChart"></div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="card top-selling overflow-auto">
                                <div className="filter">
                                    <button className="icon" data-bs-toggle="dropdown">
                                        <i className="bi bi-three-dots"></i>
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                        <li className="dropdown-header text-start">
                                            <h6>Filter</h6>
                                        </li>
                                        <li><button className="dropdown-item">Today</button></li>
                                        <li><button className="dropdown-item">This Month</button></li>
                                        <li><button className="dropdown-item">This Year</button></li>
                                    </ul>
                                </div>

                                <div className="card-body pb-0">
                                    <h5 className="card-title">Website Traffic <span>| Today</span></h5>
                                    <table className="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col">Preview</th>
                                                <th scope="col">Product</th>
                                                <th scope="col">Price</th>
                                                <th scope="col">Sold</th>
                                                <th scope="col">Revenue</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">
                                                    <button onClick={() => {}} style={{ background: 'none', border: 'none', padding: 0 }}>
                                                        <img src="assets/img/product-1.jpg" alt="" />
                                                    </button>
                                                </th>
                                                <td><button className="text-primary fw-bold" style={{ background: 'none', border: 'none', padding: 0 }}>Ut inventore ipsa voluptas nulla</button></td>
                                                <td>$64</td>
                                                <td className="fw-bold">124</td>
                                                <td>$5,828</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">
                                                    <button onClick={() => {}} style={{ background: 'none', border: 'none', padding: 0 }}>
                                                        <img src="assets/img/product-2.jpg" alt="" />
                                                    </button>
                                                </th>
                                                <td><button className="text-primary fw-bold" style={{ background: 'none', border: 'none', padding: 0 }}>Exercitationem similique doloremque</button></td>
                                                <td>$46</td>
                                                <td className="fw-bold">98</td>
                                                <td>$4,508</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">
                                                    <button onClick={() => {}} style={{ background: 'none', border: 'none', padding: 0 }}>
                                                        <img src="assets/img/product-3.jpg" alt="" />
                                                    </button>
                                                </th>
                                                <td><button className="text-primary fw-bold" style={{ background: 'none', border: 'none', padding: 0 }}>Doloribus nisi exercitationem</button></td>
                                                <td>$59</td>
                                                <td className="fw-bold">74</td>
                                                <td>$4,366</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">
                                                    <button onClick={() => {}} style={{ background: 'none', border: 'none', padding: 0 }}>
                                                        <img src="assets/img/product-4.jpg" alt="" />
                                                    </button>
                                                </th>
                                                <td><button className="text-primary fw-bold" style={{ background: 'none', border: 'none', padding: 0 }}>Officiis quaerat sint rerum error</button></td>
                                                <td>$32</td>
                                                <td className="fw-bold">63</td>
                                                <td>$2,016</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">
                                                    <button onClick={() => {}} style={{ background: 'none', border: 'none', padding: 0 }}>
                                                        <img src="assets/img/product-5.jpg" alt="" />
                                                    </button>
                                                </th>
                                                <td><button className="text-primary fw-bold" style={{ background: 'none', border: 'none', padding: 0 }}>Sit unde debitis delectus repellendus</button></td>
                                                <td>$79</td>
                                                <td className="fw-bold">41</td>
                                                <td>$3,239</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="card">
                        <div className="filter">
                            <button className="icon" data-bs-toggle="dropdown">
                                <i className="bi bi-three-dots"></i>
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                <li className="dropdown-header text-start">
                                    <h6>Filter</h6>
                                </li>
                                <li><button className="dropdown-item">Today</button></li>
                                <li><button className="dropdown-item">This Month</button></li>
                                <li><button className="dropdown-item">This Year</button></li>
                            </ul>
                        </div>

                        <div className="card-body pb-0">
                            <h5 className="card-title">News & Updates <span>| Today</span></h5>
                            <div className="news">
                                <div className="post-item clearfix">
                                    <h4><button onClick={() => {}} style={{ background: 'none', border: 'none', padding: 0 }}>Nihil blanditiis at in nihil autem</button></h4>
                                    <p>Sit recusandae non aspernatur laboriosam. Quia enim eligendi sed ut harum...</p>
                                </div>
                                <div className="post-item clearfix">
                                    <h4><button onClick={() => {}} style={{ background: 'none', border: 'none', padding: 0 }}>Quidem autem et impedit</button></h4>
                                    <p>Illo nemo neque maiores vitae officiis cum eum turos elan dries werona nande...</p>
                                </div>
                                <div className="post-item clearfix">
                                    <h4><button onClick={() => {}} style={{ background: 'none', border: 'none', padding: 0 }}>Id quia et et ut maxime similique occaecati ut</button></h4>
                                    <p>Fugiat voluptas vero eaque accusantium eos. Consequuntur sed ipsam et totam...</p>
                                </div>
                                <div className="post-item clearfix">
                                    <h4><button onClick={() => {}} style={{ background: 'none', border: 'none', padding: 0 }}>Laborum corporis quo dara net para</button></h4>
                                    <p>Qui enim quia optio. Eligendi aut asperiores enim repellendusvel rerum cuder...</p>
                                </div>
                                <div className="post-item clearfix">
                                    <h4><button onClick={() => {}} style={{ background: 'none', border: 'none', padding: 0 }}>Et dolores corrupti quae illo quod dolor</button></h4>
                                    <p>Odit ut eveniet modi reiciendis. Atque cupiditate libero beatae dignissimos eius...</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MySpaceDocument;
