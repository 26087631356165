import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

gsap.registerPlugin(MotionPathPlugin);

const HeaderWithPen = () => {
  useEffect(() => {
    const duration = 5;
    gsap.fromTo(
      "#path",
      { strokeDasharray: "2000", strokeDashoffset: "2000" },
      { strokeDashoffset: "0", duration: duration, ease: "power2.inOut" }
    );
    gsap.to("#pen", {
      duration: duration,
      motionPath: {
        path: "#path",
        align: "#path",
        alignOrigin: [0.5, 0.5],
        autoRotate: true,
      },
      ease: "power2.inOut",
    });
  }, []);

  useEffect(() => {
    const txtElement = document.querySelector(".txt-type");
    if (txtElement) {
      const words = JSON.parse(txtElement.getAttribute("data-words"));
      const wait = parseInt(txtElement.getAttribute("data-wait"), 10);
      new TypeWriter(txtElement, words, wait);
    }
  }, []);

  return (
    <section id="home-header" className="d-flex align-items-center">
      <div className="container">
        <div className="row">
          <div className="d-flex flex-column justify-content-center pt-1 pt-lg-0 order-2 order-lg-1">
            <center className="containerTitles">
              <h1 className="titre1">
                Créez des sites web{" "}
                <span
                  className="txt-type"
                  data-wait="2500"
                  data-words='["simplement", "rapidement", "sereinement"]'
                ></span>
                <i className="fas fa-pencil-alt"></i>
              </h1>
              <h2 className="titre2">
                Laissez{" "}
                <Link to="/" className="author">
                  nos experts
                </Link>{" "}
                vous accompagner vers la réussite de votre projet.
              </h2>
            </center>
          </div>
        </div>
        <div className="button-container">
          <Link className="btn-get-started" to="/pricing">
            Créer votre Site
          </Link>
        </div>
      </div>
    </section>
  );
};

class TypeWriter {
  constructor(txtElement, words, wait = 3000) {
    this.txtElement = txtElement;
    this.words = words;
    this.txt = "";
    this.wordIndex = 0;
    this.wait = parseInt(wait, 10);
    this.type();
    this.isDeleting = false;
  }

  type() {
    const current = this.wordIndex % this.words.length;
    const fullTxt = this.words[current];

    if (this.isDeleting) {
      this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
      this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    const colorClass = this.getColorClass(current);
    this.txtElement.innerHTML = `<span class="txt ${colorClass}">${this.txt}</span>`;
    let typeSpeed = 300;
    const typingElement = document.querySelector(".fas");

    if (this.isDeleting) {
      typeSpeed /= 4;
    }

    if (typingElement) {
      if (this.isDeleting) {
        typingElement.className = "fas fa-pencil-alt erasing-animation";
      } else {
        typingElement.className = "fas fa-pencil-alt writing-animation";
      }

      if (!this.isDeleting && this.txt === fullTxt) {
        typeSpeed = this.wait;
        this.isDeleting = true;
        typingElement.className = "fas fa-pencil-alt";
      } else if (this.isDeleting && this.txt === "") {
        this.isDeleting = false;
        this.wordIndex++;
        typeSpeed = 1000;
      }
    }

    setTimeout(() => this.type(), typeSpeed);
  }

  getColorClass(index) {
    const colorClasses = ["color1", "color2", "color3"];
    return colorClasses[index % colorClasses.length];
  }
}

export default HeaderWithPen;
