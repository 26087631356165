import React, { useState, useEffect } from 'react';
import { Form, Button, Alert, Row, ProgressBar } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';

function SignUp() {
  const [signupData, setSignupData] = useState({ username: '', password: '', firstName: '', lastName: '', address: '', dateOfBirth: '' });
  const [showSignupAgeError, setShowSignupAgeError] = useState(false);
  const [showUserExistError, setShowUserExistError] = useState(false); // Nouvel état pour gérer l'affichage du message d'erreur utilisateur existant
  const [passwordError, setPasswordError] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(0);

  const { setToken } = useAuth();
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      window.location.reload();
      history.push('/MySpace');
    }
  }, [history]);

  const calculatePasswordStrength = (password) => {
    let strength = 0;

    // Vérifie si le mot de passe a au moins 12 caractères
    if (password.length >= 12) {
      strength += 1;
    }
    // Vérifie si le mot de passe a au moins 5 chiffres
    const digitsCount = password.replace(/[^0-9]/g, '').length;
    if (digitsCount >= 5) {
      strength += 1;
    }
    // Vérifie si le mot de passe contient un caractère spécial
    if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      strength += 1;
    }

    return strength;
  };

  const validatePassword = (password) => {
    let errorMessage = '';
  
    // Vérifie si le mot de passe a au moins 12 caractères
    if (password.length < 10) {
      errorMessage += 'Le mot de passe doit contenir au moins 10 caractères. ';
    }
    // Vérifie si le mot de passe a au moins 5 chiffres
    const digitsCount = password.replace(/[^0-9]/g, '').length;
    if (digitsCount < 3) {
      errorMessage += 'Le mot de passe doit contenir au moins 3 chiffres. ';
    }
    // Vérifie si le mot de passe contient un caractère spécial
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errorMessage += 'Le mot de passe doit contenir au moins un caractère spécial.';
    }
  
    // Retourne le message d'erreur complet
    return errorMessage.trim();
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    const strength = calculatePasswordStrength(password);
    setPasswordStrength(strength);
    setSignupData({ ...signupData, password: password });
  };

  const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  };

  const checkUserExist = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_AUTH_URL || 'http://localhost:3100/api';
      const response = await axios.post(`${apiUrl}/auth/GetUserExist`, { username: signupData.username });
      return response.data;
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  const handleSignupSubmit = async (event) => {
    event.preventDefault();
    const age = calculateAge(signupData.dateOfBirth);
    if (age < 18) {
      setShowSignupAgeError(true);
      return;
    } else {
      setShowSignupAgeError(false);
    }

    const passwordError = validatePassword(signupData.password);
    if (passwordError) {
      setPasswordError(passwordError);
      return;
    } else {
      setPasswordError('');
    }

    try {
      const userExistsResponse = await checkUserExist();
  
      if (!userExistsResponse.userExists) {
        setShowUserExistError(false);
        const apiUrl = process.env.REACT_APP_API_AUTH_URL || 'http://localhost:3100/api';
        const response = await axios.post(`${apiUrl}/auth/signup`, signupData);
        if (response.status === 200) {
          const loginResponse = await axios.post(`${apiUrl}/auth/login`, signupData);
          if (loginResponse.status === 200) {
            setToken(loginResponse.data.token);
            window.location.reload();
            history.push('/SignupSuccess');
          } else {
            setShowSignupAgeError(true);
          }
        } else {
          setShowSignupAgeError(true);
        }
      } else {
        setShowUserExistError(true);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div>
      <div className="section-title">
        <h2>S'inscrire</h2>
        <p>Rejoignez dès maintenant la communauté MyHappyWeb pour donner vie à vos idées en créant des sites web qui vous ressemblent</p>
      </div>
      {showUserExistError && <Alert variant="danger">L'utilisateur existe déjà.</Alert>}
      <Form onSubmit={handleSignupSubmit}>
        <Row className="mb-6">
          <Form.Group controlId="signupEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Entrez votre email"
              value={signupData.username}
              onChange={(e) => setSignupData({ ...signupData, username: e.target.value })}
              required
              autoComplete="username" 
            />
          </Form.Group>
        </Row>
        <Row className="mb-6">
          <Form.Group controlId="signupPassword">
            <Form.Label>Mot de passe</Form.Label>
            <Form.Control
              type="password"
              placeholder="Mot de passe"
              value={signupData.password}
              onChange={handlePasswordChange}
              required
              autoComplete="current-password" // Ajout de l'attribut autocomplete
            />
            <ProgressBar
              now={(passwordStrength / 3) * 100}
              label={`${passwordStrength}/3`}
              className="custom-progress-bar"
            />
            {passwordError && <Alert variant="danger">{passwordError}</Alert>}
          </Form.Group>
        </Row>
        <Row className="mb-6">
          <Form.Group controlId="signupFirstName">
            <Form.Label>Prénom</Form.Label>
            <Form.Control
              type="text"
              placeholder="Entrez votre prénom"
              value={signupData.firstName}
              onChange={(e) => setSignupData({ ...signupData, firstName: e.target.value })}
              required
            />
          </Form.Group>
          <Form.Group controlId="signupLastName">
            <Form.Label>Nom</Form.Label>
            <Form.Control
              type="text"
              placeholder="Entrez votre nom"
              value={signupData.lastName}
              onChange={(e) => setSignupData({ ...signupData, lastName: e.target.value })}
              required
            />
          </Form.Group>
        </Row>
        <Form.Group controlId="signupAddress">
          <Form.Label>Adresse</Form.Label>
          <Form.Control
            type="text"
            placeholder="Entrez votre adresse"
            value={signupData.address}
            onChange={(e) => setSignupData({ ...signupData, address: e.target.value })}
            required
          />
        </Form.Group>
        <Form.Group controlId="signupDateOfBirth">
          <Form.Label>Date de naissance</Form.Label>
          <Form.Control
            type="date"
            placeholder="Entrez votre date de naissance"
            value={signupData.dateOfBirth}
            onChange={(e) => setSignupData({ ...signupData, dateOfBirth: e.target.value })}
            required
          />
        </Form.Group>

        {showSignupAgeError && <Alert variant="danger">Vous devez avoir plus de 18 ans pour vous inscrire.</Alert>}
        <Button className="buy-btn btn-signup" type="submit">
          S'inscrire
        </Button>
      </Form>
    </div>
  );
}

export default SignUp;
