import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SignUp from './SignUp';
import Navbar from './../basic/Navbar';

function SignUpPage() {
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      window.location.reload();
      history.push('/MySpace');
    }
  }, [history]);

  return (
    <>
    <Navbar/>
    <section id="signup" className="signup custom-signup">

    <div className="container">
        <div className="row">
            <div className="col" ></div>
            <div className="col-6" >
              <SignUp />
            </div>
            <div className="col"></div>
          </div>
      </div>

  </section>
  </>
  );
}

export default SignUpPage;
