import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Footer from './basic/Footer';
import Navbar from './basic/Navbar';
import axios from 'axios';

function AbonmentReturn() {
  const [paymentStatus, setPaymentStatus] = useState('');
  const [siteCreated, setSiteCreated] = useState(false);
  const isCreatedRef = useRef(false); // Utilisation de useRef pour suivre la création du site
  const history = useHistory();
  const REACT_APP_API_COMPONENT_URL = process.env.REACT_APP_API_COMPONENT_URL;
  const REACT_APP_API_STRIPE_URL = process.env.REACT_APP_API_STRIPE_URL;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const username = urlParams.get('username');
    const themeName = urlParams.get('themeName');
    const siteUrl = urlParams.get('urlSite');
    const sessionId = urlParams.get('session_id');
    const token = localStorage.getItem('token'); 

    const createSite = async (username, themeName, siteUrl) => {
      try {
        isCreatedRef.current = true;

        const response = await axios.post(`${REACT_APP_API_COMPONENT_URL}/site/create`, {
          username: username,
          siteName: siteUrl,
          siteUrl: siteUrl,
          theme: themeName
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          }
        });

        if(response.status === 409 || response.status === 200 || response.status === 201) {
          if (response.status === 200 || response.status === 201) {
            setSiteCreated(true);
          }
          try {
            const response = await axios.get(`${REACT_APP_API_STRIPE_URL}/stripe/session_status`, {
              params: { session_id: sessionId },
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
              }
            });
            if (response.status === 200) {
              const data = response.data;
              setPaymentStatus(data.status);
              await addBddSubscriptionId(sessionId, siteUrl, token);
            } else {
              console.error('Erreur lors de la récupération du statut de paiement');
            }
          } catch (error) {
            console.error('Erreur lors de la récupération du statut de paiement', error);
          }
        } else {
          console.error('Erreur lors de la création du site');
        }
      } catch (error) {
        console.error('Erreur lors de la création du site', error);
      }
    };

    const addBddSubscriptionId = async (sessionId, siteUrl, token) => {
      try {
        const response = await axios.post(`${REACT_APP_API_STRIPE_URL}/stripe/addBddSubscriptionId`, {}, {
          params: { session_id: sessionId, siteUrl: siteUrl },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          }
        });

        if (response.status === 200) {
          console.log('Subscription ID and site URL added successfully:', response.data.subscriptionId);
        } else {
          console.error('Erreur lors de l\'ajout de l\'ID d\'abonnement et de l\'URL du site');
        }
      } catch (error) {
        console.error('Erreur lors de l\'ajout de l\'ID d\'abonnement et de l\'URL du site', error);
      }
    };

    if (!isCreatedRef.current) {
      createSite(username, themeName, siteUrl);
    }
  }, [paymentStatus, siteCreated, REACT_APP_API_COMPONENT_URL, REACT_APP_API_STRIPE_URL]);

  // Fonction pour rediriger vers la page "MySpace"
  const handleRedirectToMySpace = () => {
    history.push('/myspace');
  };

  return (
    <>
      <Navbar />
      <section id="abonmentReturn" className="abonmentReturn section-bg">
        <div className="container">
          <div className="row">
            <div className="col"></div>
            <div className="col-6">
              <h1>AbonmentReturn</h1>
              <p>Ceci est le contenu de la AbonmentReturn.</p>
              {paymentStatus === 'open' && <p>Le paiement est en cours de traitement.</p>}
              {paymentStatus === 'complete' && (
                <>
                  <p>Le paiement a été effectué avec succès. cliquez sur le boutton ci-dessous pour accéder à votre Dashboard. L'accès à votre site web peut prendre en moyenne 15 minutes à être disponible.</p>
                  <button onClick={handleRedirectToMySpace}>Accéder à MySpace</button>
                </>
              )}
            </div>
            <div className="col"></div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default AbonmentReturn;