import axios from 'axios';

export async function handleSignupSubmit(signupData, selectedTheme, setToken, nextStep, setShowSignupError) {
  try {
    console.log("Signup data:", signupData);
    const REACT_APP_API_AUTH_URL = process.env.REACT_APP_API_AUTH_URL;
    
    // Requête pour l'inscription
    const response = await axios.post(`${REACT_APP_API_AUTH_URL}/auth/signup`, signupData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = response.data;
    console.log("Signup response:", data);
    
    if (response.status === 200) {
      // Requête pour la connexion
      const loginResponse = await axios.post(`${REACT_APP_API_AUTH_URL}/auth/login`, signupData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const loginData = loginResponse.data;
      if (loginResponse.status === 200) {
        setToken(loginData.token);
        localStorage.setItem('token', loginData.token); // Stocker le token dans le localStorage
        nextStep(selectedTheme); // Passer à la prochaine étape si la connexion réussit
      } else {
        setShowSignupError(true);
      }
    } else {
      setShowSignupError(true);
    }
  } catch (error) {
    console.error('Error:', error);
    setShowSignupError(true); // Afficher l'erreur en cas d'exception
  }
};
