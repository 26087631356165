import React from 'react';

const Header = () => {
  return (
    <header >
      <script async src="https://js.stripe.com/v3/"></script>
    </header >
  );
};

export default Header;
