import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function TariffGrid() {
  const location = useLocation();

  return (
    <>
      <section id="pricing" className="pricing">
        <div className="container" data-aos="fade-up">
        
        {location.pathname !== "/" && (
          <Link to="/">
            <i className="bi bi-arrow-left fs-3"></i>
          </Link>
        )}

          <div className="section-title">
            <h2>Pricing</h2>
            <p>Optez pour la proposition qui vous correspond le mieux et réalisez votre vision numérique avec nous.</p>
          </div>

          <div className="row">

            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
              <div className="box">
                <h3>WORDPRESS</h3>
                <h4>
                  <span>à partir de </span> 9,99 <sup>€ ttc</sup>
                  <span className='per-month'> par mois</span>
                </h4>
                <ul>
                  <li><i className="bx bx-check"></i> Large Gamme de Thèmes et de Plugins</li>
                  <li><i className="bx bx-check"></i> Herbergement</li>
                  <li><i className="bx bx-check"></i> Nom de domaine</li>
                  <li><i className="bx bx-check"></i> Intégration des Réseaux Sociaux</li>
                  <li><i className="bx bx-check"></i> Accessibilité sur les moteurs de recherche</li>
                  <li><i className="bx bx-check"></i> Haute disponibilité, réactif et rapide</li>
                  <li><i className="bx bx-check"></i> HTTPS & SSL</li>
                </ul>
                <center>
                <Link to={`/customwebsite`} className="buy-btn">Get Started</Link>
                </center>
              </div>
            </div>

            <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="200">
              <div className="box featured">
                <h3>ESSENTIEL</h3>
                <h4>
                  <span>à partir de </span> 5,99 <sup>€ ttc</sup>
                  <span className='per-month'> par mois</span>
                </h4>
                <ul>
                  <li><i className="bx bx-check"></i> Large Gamme de Thèmes</li>
                  <li><i className="bx bx-check"></i> Modifications illimitées et à votre image</li>
                  <li><i className="bx bx-check"></i> Nom de domaine</li>
                  <li><i className="bx bx-check"></i> Intégration des Réseaux Sociaux</li>
                  <li><i className="bx bx-check"></i> Accessibilité sur les moteurs de recherche</li>
                  <li><i className="bx bx-check"></i> Haute disponibilité, réactif et rapide</li>
                  <li><i className="bx bx-check"></i> HTTPS & SSL</li>
                </ul>
                <center>
                <Link to={`/customwebsite`} className="buy-btn">Get Started</Link>
                </center>

              </div>
            </div>

            <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="300">
              <div className="box">
                <h3>PREMIUM</h3>

                <h4 className='devis'>
                Prix sur demande 
                
                <span className='per-month'> <Link to={`/contact`} > Transformez vos idées en réalité digitale Demander un devis</Link></span>

                </h4>
                <ul>
                  <li><i className="bx bx-check"></i> Developpement Web et Mobile FullStack</li>
                  <li><i className="bx bx-check"></i> DevSecOps</li>
                  <li><i className="bx bx-check"></i> Big Data</li>
                  <li><i className="bx bx-check"></i> Cloud (AWS / AZURE / GCP)</li>
                  <li><i className="bx bx-check"></i> CyberSecurité</li>
                  <li><i className="bx bx-check"></i> IA</li>
                </ul>
                <center>
                <Link to={`/contact`} className="buy-btn">Contactez-nous</Link>
                </center>
              </div>
            </div>

          </div>

        </div>
      </section>
    </>
  );
}
