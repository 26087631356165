import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Card, Button, Form, Modal, Table, Row, Col, Alert, Spinner  } from 'react-bootstrap';

function MySpaceSettings({ userData, selectedSite, subscriptionDetails, subscriptionError }) {
    const [isEditing, setIsEditing] = useState(false);
    const [isChangingPassword, setIsChangingPassword] = useState(false);
    const [first_name, setFirst_name] = useState(userData ? userData.first_name : '');
    const [last_name, setLast_name] = useState(userData ? userData.last_name : '');
    const [address, setAddress] = useState(userData ? userData.address : '');
    const [newPassword, setNewPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [showChangePasswordButton, setShowChangePasswordButton] = useState(true);
    const [changePasswordError, setChangePasswordError] = useState('');
    const [changePasswordSuccess, setChangePasswordSuccess] = useState('');
    const [username, setUsername] = useState(userData ? userData.username : '');
    const [showConfirmation, setShowConfirmation] = useState(false);

    const REACT_APP_API_COMPONENT_URL = process.env.REACT_APP_API_COMPONENT_URL;
    const REACT_APP_API_AUTH_URL = process.env.REACT_APP_API_AUTH_URL;
    const REACT_APP_API_STRIPE_URL = process.env.REACT_APP_API_STRIPE_URL; // Assurez-vous d'ajouter cette URL dans votre fichier .env

    const token = localStorage.getItem('token');

    useEffect(() => {
        if (userData) {
            setUsername(userData.username);
            setFirst_name(userData.first_name);
            setLast_name(userData.last_name);
            setAddress(userData.address);
        }
    }, [userData]);

    const handleEdit = () => {
        setIsEditing(!isEditing);
    };

    const handlePasswordChange = () => {
        setIsChangingPassword(!isChangingPassword);
        setShowChangePasswordButton(!showChangePasswordButton);
        if (!isChangingPassword) {
            setIsEditing(false);
        }
    };

    const handlePasswordChangeSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${REACT_APP_API_AUTH_URL}/auth/changePassword`, {
                username,
                oldPassword,
                newPassword
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            const data = response.data;
            if (response.status === 200) {
                setChangePasswordSuccess(data.message);
                setChangePasswordError('');
            } else {
                setChangePasswordError(data.message);
                setChangePasswordSuccess('');
            }
        } catch (error) {
            console.error('Error:', error);
            setChangePasswordError('Une erreur est survenue lors du changement de mot de passe');
            setChangePasswordSuccess('');
        }
    };

    const updateUser = async () => {
        const updatedUserData = {
            username,
            first_name,
            last_name,
            address
        };

        try {
            const response = await axios.post(`${REACT_APP_API_AUTH_URL}/auth/updateUser`, updatedUserData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.status === 200) {
                console.log('User information updated successfully');
                window.location.reload();
            } else {
                console.error('Error updating user information');
            }
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };

    const handleDeleteSite = () => {
        axios.post(`${REACT_APP_API_COMPONENT_URL}/site/delete`, { name: selectedSite.name, theme: selectedSite.themeName }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            if (response.status === 200) {
                axios.post(`${REACT_APP_API_STRIPE_URL}/stripe/cancelSubscription`, { subscriptionId: subscriptionDetails.id }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then(response => {
                    if (response.status === 200) {
                        window.location.href = '/MySpace';
                    } else {
                        console.error('Une erreur s\'est produite lors de l\'annulation de l\'abonnement.');
                    }
                })
                .catch(error => {
                    console.error('Une erreur s\'est produite lors de l\'annulation de l\'abonnement:', error);
                });
            } else {
                console.error('Une erreur s\'est produite lors de la suppression du site.');
            }
        })
        .catch(error => {
            console.error('Une erreur s\'est produite:', error);
        });
    };

    return (
        <Container className="section dashboard">
            <Row>
                <Col lg={12}>
                    <Row>
                        <Col md={7}>
                            <Card className="top-selling overflow-auto">
                                <Card.Body>
                                    <Card.Title>
                                        Compte <span>| Informations personnelles </span>
                                        {!isEditing && (
                                            <i
                                                className="bi bi-pencil-square"
                                                style={{ cursor: 'pointer' }}
                                                onClick={handleEdit}
                                            ></i>
                                        )}
                                    </Card.Title>
                                    <Table>
                                        <tbody>
                                            <tr>
                                                <td>Login</td>
                                                <td>{username}</td>
                                            </tr>
                                            <tr>
                                                <td>Prénom</td>
                                                <td>{isEditing ? <Form.Control type="text" value={first_name} onChange={(e) => setFirst_name(e.target.value)} /> : first_name}</td>
                                            </tr>
                                            <tr>
                                                <td>Nom</td>
                                                <td>{isEditing ? <Form.Control type="text" value={last_name} onChange={(e) => setLast_name(e.target.value)} /> : last_name}</td>
                                            </tr>
                                            <tr>
                                                <td>Address</td>
                                                <td>{isEditing ? <Form.Control type="text" value={address} onChange={(e) => setAddress(e.target.value)} /> : address}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    {isEditing && (
                                        <div className="icon-container">
                                            <i className="bi bi-floppy floppy-icon" onClick={updateUser}></i>
                                            <i className="bi bi-x-circle-fill cancel-icon" onClick={() => {
                                                setIsEditing(false);
                                            }}></i>
                                        </div>
                                    )}
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={5}>
                            <Card className="top-selling overflow-auto">
                                <Card.Body>
                                    <Card.Title>Sécurité <span>| Mot de passe</span></Card.Title>
                                    {showChangePasswordButton && (
                                        <Button variant="warning" className="mr-2 mb-2" onClick={handlePasswordChange}>
                                            Change your Password
                                        </Button>
                                    )}

                                    {isChangingPassword && (
                                        <Form onSubmit={handlePasswordChangeSubmit}>
                                            <Form.Group>
                                                <Form.Label htmlFor="username" className="visually-hidden">Username</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="username"
                                                    value={username}
                                                    onChange={(e) => setUsername(e.target.value)}
                                                    autoComplete="username"
                                                    style={{ display: 'none' }}
                                                />

                                                <Form.Label htmlFor="oldPassword">Old Password</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    id="oldPassword"
                                                    value={oldPassword}
                                                    onChange={(e) => setOldPassword(e.target.value)}
                                                    autoComplete="current-password"
                                                />
                                                <Form.Label htmlFor="newPassword">New Password</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    id="newPassword"
                                                    value={newPassword}
                                                    onChange={(e) => setNewPassword(e.target.value)}
                                                    autoComplete="new-password"
                                                />
                                            </Form.Group>
                                            <div className="icon-container">
                                                <i className="bi bi-floppy floppy-icon"></i>
                                                <i className="bi bi-x-circle-fill cancel-icon" onClick={handlePasswordChange}></i>
                                            </div>
                                        </Form>
                                    )}
                                    {changePasswordError && <Alert variant="danger">{changePasswordError}</Alert>}
                                    {changePasswordSuccess && <Alert variant="success">{changePasswordSuccess}</Alert>}
                                </Card.Body>
                            </Card>
                            <Card className="top-selling overflow-auto">
                                <Card.Body>
                                    <Card.Title>Supprimer <span>| Arrêter votre abonnement</span></Card.Title>
                                    <Button variant="danger" className="mr-2 mb-2" onClick={() => setShowConfirmation(true)}>
                                        Supprimer mon site: {selectedSite.name}
                                    </Button>
                                    <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>/!\ Attention /!\</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <p>Êtes-vous sûr de vouloir supprimer le site <strong>{selectedSite.name}</strong>?</p>
                                            <p>Il n'y aura pas de retour arrière possible et votre abonnement sera automatiquement annulé.</p>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={() => setShowConfirmation(false)}>Annuler</Button>
                                            <Button variant="danger" onClick={handleDeleteSite}>Supprimer</Button>
                                        </Modal.Footer>
                                    </Modal>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={7}>
                            <Card className="top-selling overflow-auto">
                                <Card.Body>
                                    <Card.Title>
                                        Abonnement <span>| Détails de votre abonnement </span>
                                    </Card.Title>
                                    {subscriptionError ? (
                                        <div className="d-flex justify-content-center align-items-center">
                                            <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                            <span className="ms-2">Loading...</span>
                                        </div>
                                    ) : subscriptionDetails ? (
                                        <div>
                                            <p><strong>id:</strong> {subscriptionDetails.id}</p>
                                            <p><strong>Status:</strong> {subscriptionDetails.status}</p>
                                            <p><strong>Current Period Start:</strong> {new Date(subscriptionDetails.current_period_start * 1000).toLocaleDateString()}</p>
                                            <p><strong>Current Period End:</strong> {new Date(subscriptionDetails.current_period_end * 1000).toLocaleDateString()}</p>
                                            <h3>Subscription Items</h3>
                                            {subscriptionDetails.items.data.map(item => (
                                                <div key={item.id}>
                                                    <p><strong>Amount:</strong> {item.plan.amount / 100} {item.plan.currency.toUpperCase()}</p>
                                                    <p><strong>Interval:</strong> {item.plan.interval}</p>
                                                    <p><strong>Tax Rates:</strong> {item.tax_rates.map(tax => `${tax.display_name} (${tax.percentage}%)`).join(', ')}</p>
                                                    <p><strong>Product Name:</strong> {item.price.product_name}</p> {/* Ajout du nom du produit */}
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className="d-flex justify-content-center align-items-center">
                                            <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                            <span className="ms-2">Loading...</span>
                                        </div>

                                    )}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default MySpaceSettings;
