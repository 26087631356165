import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext'; // Importez le contexte Auth

function MenuSidebar({ userData, onContentChange, onSiteChange }) {
    const [sites, setSites] = useState([]);
    const [selectedSite, setSelectedSite] = useState(null);
    const [redirectToTest, setRedirectToTest] = useState(false);
    const [selectedContent, setSelectedContent] = useState('dashboard');
    const { token, checkTokenValidity } = useAuth(); // Récupérez le token et checkTokenValidity du contexte

    const handleItemClick = (content) => {
        checkTokenValidity(token, handleInvalidToken); // Vérifiez la validité du token
        setSelectedContent(content);
        onContentChange(content);
    };

    const handleSiteClick = (site) => {
        checkTokenValidity(token, handleInvalidToken); // Vérifiez la validité du token
        setSelectedSite(site);
        onSiteChange(site);
    };

    const handleInvalidToken = () => {
        localStorage.removeItem('token');
        window.location.href = '/'; // Redirigez vers la page d'accueil
    };

    useEffect(() => {
        if (userData && userData.username) {
            const REACT_APP_API_COMPONENT_URL = process.env.REACT_APP_API_COMPONENT_URL;

            axios.post(`${REACT_APP_API_COMPONENT_URL}/site/list`, { username: userData.username }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            })
                .then(response => {
                    setSites(response.data);
                    setSelectedSite(response.data.length > 0 ? response.data[0] : null);
                    if (response.data.length === 0) {
                        setRedirectToTest(true);
                    }
                })
                .catch(error => {
                    console.error('Erreur lors de la récupération des sites:', error);
                });
        }
    }, [userData, token]);

    if (redirectToTest) {
        return <Redirect to="/CustomWebsite" />;
    }

    return (
        <>
            <aside id="sidebar" className="sidebar">
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <button className="nav-link collapsed" data-bs-target="#sites-nav" data-bs-toggle="collapse">
                            <i className="bi bi-globe fs-4"></i>
                            <span>{selectedSite ? selectedSite.name : 'Sites'}</span>
                            <i className="bi bi-chevron-down ms-auto"></i>
                        </button>
                        <ul id="sites-nav" className="nav-content collapse" data-bs-parent="#sidebar-nav">
                            {sites.map(site => (
                                <li key={site.id} className="nav-content-item">
                                    <button className="nav-content-link" onClick={() => handleSiteClick(site)}>
                                        <i className="bi bi-circle"></i><span>{site.name}</span>
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </li>
                    <li className="nav-item">
                        <button className={`nav-link ${selectedContent === 'dashboard' ? '' : 'collapsed'}`} onClick={() => handleItemClick('dashboard')}>
                            <i className="bi bi-grid fs-3"></i>
                            <span>Dashboard</span>
                        </button>
                    </li>
                    <li className="nav-item">
                        <button className={`nav-link ${selectedContent === 'settings' ? '' : 'collapsed'}`} onClick={() => handleItemClick('settings')}>
                            <i className="bi bi-gear fs-4"></i><span>Paramètres</span>
                        </button>
                    </li>
                    <li className="nav-item">
                        <button className={`nav-link ${selectedContent === 'help' ? '' : 'collapsed'}`} onClick={() => handleItemClick('help')}>
                            <i className="bi bi-info-circle-fill fs-4"></i><span>Contact & F.A.Q</span>
                        </button>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link collapsed" href="/Pricing">
                            <i className="bi bi-plus-lg fs-4"></i><span>Créer un nouveau site</span>
                        </a>
                    </li>
                </ul>
            </aside>
        </>
    );
}

export default MenuSidebar;
