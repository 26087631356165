// Page2.js
import React from 'react';
import Footer from './basic/Footer';
import Navbar from './basic/Navbar';

function NotFound() {
  return (
    <>
      <Navbar/>
      <section id="notfound" className="notfound">

      <div className="container">
        <div className="row">
          <div className="col"></div>
          <div className="col-6">
            <h1>404 :(</h1>
            <br ></br>

            <p>Nous sommes désolés, mais la page que vous recherchez semble introuvable. Il se peut qu'elle ait été déplacée, supprimée ou qu'elle n'ait jamais existé.</p>

            <p>En attendant, vous pouvez retourner à notre page d'accueil. Ou, si vous pensez qu'il s'agit d'une erreur, vous pouvez nous contacter pour nous en informer.</p>

            <p>Nous vous remercions de votre compréhension et de votre patience.</p>


          </div>
          <div className="col"></div>
        </div>
      </div>
      </section>
      <Footer />
    </>
  );
}

export default NotFound;
