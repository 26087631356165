import React from 'react';
// import { jwtDecode } from "jwt-decode";
import Contact from '../Contact';

function MySpaceHelp() {


    //useEffect(() => {
      // Récupérer le token depuis le localStorage ou le state de l'application
      //const token = localStorage.getItem('token'); // Suppose que vous avez stocké le token dans le localStorage avec la clé 'token'
  
      // Décoder le token pour obtenir son contenu
      //if (token) {
  
 //       const decodedToken = jwtDecode(token);
  //    }
   // }, []);
  
  return (
                    
        <Contact />

  );
}

export default MySpaceHelp;
