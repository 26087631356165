import React from 'react';

function SignupSucess() {

  return (
    <div className="container">
      <div className="row">

          <h1>SignupSucess</h1>
          <div className="col" ></div>
          <div className="col-6" >
                Bienvenue 
          </div> 
          <div className="col"></div>
        </div>
    </div>
  );
}

export default SignupSucess;
