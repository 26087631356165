import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { Link } from 'react-router-dom';
import axios from 'axios';

function SignIn() {
  const [loginData, setLoginData] = useState({ username: '', password: '' });
  const [showLoginError, setShowLoginError] = useState(false);
  const [showSignupForm] = useState(false);
  const { setToken } = useAuth();
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      window.location.reload();
      history.push('/MySpace');
    }
  }, [history]);

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    try {
      const apiUrl = process.env.REACT_APP_API_AUTH_URL || 'http://localhost:3100/api';
      const response = await axios.post(`${apiUrl}/auth/login`, loginData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = response.data;
      if (response.status === 200) {
        setToken(data.token);
        window.location.reload();
        history.push('/MySpace');
      } else {
        setShowLoginError(true);
      }
    } catch (error) {
      console.error('Error:', error);
      setShowLoginError(true); // Afficher l'erreur en cas de problème avec la requête
    }
  };
      
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div className="section-title">
        <h2>Se connecter</h2>
        <p>Accès facile et sécurisé à vos sites web</p>
      </div>

      <Form onSubmit={handleLoginSubmit} style={{ display: showSignupForm ? 'none' : 'block', width: '300px' }}>
        <Row className="mb-3">
          <Form.Group controlId="loginEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Entrez votre email"
              value={loginData.username}
              onChange={(e) => setLoginData({ ...loginData, username: e.target.value })}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group controlId="loginPassword">
            <Form.Label>Mot de passe</Form.Label>
            <Form.Control
              type="password"
              placeholder="Mot de passe"
              value={loginData.password}
              onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
            />
          </Form.Group>
        </Row>
        {showLoginError && <Alert variant="danger">Identifiants incorrects</Alert>}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Link className="buy-btn btn-signup" to="/SignUpPage" variant="primary">
            S'inscrire
          </Link>
          <Button className="buy-btn btn-signin" type="submit">
            Connexion
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default SignIn;
