import React, { useCallback, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { Button, InputGroup, FormControl } from 'react-bootstrap';
import axios from 'axios';

// Vérifiez que les variables d'environnement sont bien définies
const stripeApiKey = process.env.REACT_APP_KEY_API_STRIPE;
const apiUrl = process.env.REACT_APP_API_STRIPE_URL;

if (!stripeApiKey) {
  throw new Error("Missing Stripe API Key. Please check your environment variables.");
}

if (!apiUrl) {
  throw new Error("Missing API URL. Please check your environment variables.");
}

const stripePromise = loadStripe(stripeApiKey);

export default function StripePricingTable({ userName, theme, urlSite, nameSite, priceIds, prevStep }) { // Recevoir priceIds en props
  const [token] = useState(localStorage.getItem('token') || '');
  const [showCouponForm, setShowCouponForm] = useState(false);
  const [promoCodeInput, setPromoCodeInput] = useState('');
  const REACT_APP_URL = process.env.REACT_APP_URL;

  const fetchClientSecret = useCallback(() => {
    const priceItems = priceIds.map(id => ({ idPrice: id, quantity: 1 })); // Map priceIds to priceItems
  
    return axios.post(`${apiUrl}/stripe/createCheckoutSession`, {
      email: userName,
      themeName: theme.name,
      urlSite: urlSite,
      nameSite: nameSite,
      priceItems: priceItems, // Send priceItems
      promoCode: promoCodeInput,
      returnUrl: REACT_APP_URL
    }, {
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      }
    })
    .then((response) => response.data.clientSecret);
  }, [userName, theme, urlSite, nameSite, promoCodeInput, token, REACT_APP_URL, priceIds]);
  
  const handleApplyCoupon = () => {
    setShowCouponForm(true);
  }

  const options = {
    fetchClientSecret,
  };

  return (
    <div className="container">
      <div className="row">
        {showCouponForm ? (
          <>
            <EmbeddedCheckoutProvider
              stripe={stripePromise}
              options={options}
            >
              <div className="small-form-container">
                <EmbeddedCheckout />
              </div>
            </EmbeddedCheckoutProvider>
            {promoCodeInput}
          </>
        ) : (
          <>
            <EmbeddedCheckoutProvider
              stripe={stripePromise}
              options={options}
            >
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>

            <InputGroup>
              <FormControl
                type="text"
                className="form-control"
                id="urlSite"
                value={promoCodeInput}
                onChange={(e) => {
                  setPromoCodeInput(e.target.value);
                }}
                required
              />
              <Button className="buy-btn custom" variant="outline-secondary" onClick={handleApplyCoupon}>
                Promo
              </Button>
            </InputGroup>
            <Button className="buy-btn" variant="secondary" onClick={prevStep}>Précédent</Button>{' '}
          </>
        )}
      </div>
    </div>
  )
}