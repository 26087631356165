// MySpace.js
import React, { useState, useEffect, useCallback } from 'react';
import MenuSidebar from './MenuSidebar';
import MySpaceDashboard from './MySpaceDashboard';
import MySpaceSettings from './MySpaceSettings';
import MySpaceDocument from './MySpaceDocument';
import MySpaceHelp from './MySpaceHelp';
import '../../assets/css/style-myspace.css';
import { jwtDecode } from "jwt-decode"; // Corrigé l'import de jwtDecode
import Navbar from '../basic/Navbar';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

function MySpace() {
  const [selectedContent, setSelectedContent] = useState('dashboard');
  const [selectedSite, setSelectedSite] = useState(null);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [subscriptionError, setSubscriptionError] = useState(null); // Nouvel état pour stocker les erreurs
  const REACT_APP_API_COMPONENT_URL = process.env.REACT_APP_API_COMPONENT_URL;
  const REACT_APP_API_AUTH_URL = process.env.REACT_APP_API_AUTH_URL;
  const REACT_APP_API_STRIPE_URL = process.env.REACT_APP_API_STRIPE_URL;

  const [userData, setUserData] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      history.push('/');
      window.location.reload();
    }
  }, [history]);

  const fetchSubscriptionDetails = useCallback(async (site) => {
    if (!site || !userData) return;
  
    try {
      setSubscriptionDetails(null);
      const token = localStorage.getItem('token');
      const formattedUrl = site.url.replace(/^https?:\/\//, '');
      const siteUrl = formattedUrl.endsWith('.') ? formattedUrl.slice(0, -1) : formattedUrl;
  
      const response = await axios.get(`${REACT_APP_API_STRIPE_URL}/stripe/getSubscriptionDetails`, {
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        params: {
          username: userData.username,
          siteUrl: siteUrl
        }
      });
      setSubscriptionDetails(response.data);
      setSubscriptionError(null); // Réinitialiser l'erreur en cas de succès
    } catch (error) {
      setSubscriptionError('Erreur lors de la récupération des détails de l\'abonnement');
      setSubscriptionDetails(null); // Réinitialiser les détails en cas d'erreur
    }
  }, [userData, REACT_APP_API_STRIPE_URL]);
  
 // useEffect(() => {
 //   fetchSubscriptionDetails();
 // }, [selectedSite, userData, fetchSubscriptionDetails]);

  const getUserInfo = useCallback((yourUserName) => {
    const token = localStorage.getItem('token');
    const requestData = { yourUserName };
    axios.post(`${REACT_APP_API_AUTH_URL}/auth/GetUserInfo`, requestData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(response => {
      setUserData(response.data.user);
    })
    .catch(error => {
      console.error('Error fetching user info:', error);
    });
  }, [REACT_APP_API_AUTH_URL]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      getUserInfo(decodedToken.username);
    }
  }, [getUserInfo]);

  useEffect(() => {
    if (userData) {
      const token = localStorage.getItem('token');

      axios.post(`${REACT_APP_API_COMPONENT_URL}/site/list`, { username: userData.username }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      })
      .then(response => {
        if (response.status !== 200) {
          throw new Error('Erreur lors de la récupération des données');
        }
        return response.data;
      })
      .then(data => {
        if (data.length > 0) {
          setSelectedSite(data[0]);
          fetchSubscriptionDetails(data[0])
        }
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des sites:', error);
      });
    }
  }, [userData, REACT_APP_API_COMPONENT_URL, fetchSubscriptionDetails]);

  const handleContentChange = (content) => {
    setSelectedContent(content);
  };

  const handleSiteChange = (site) => {
    setSelectedSite(site);
    fetchSubscriptionDetails(site); // Fetch subscription details when site changes
  };

  return (
    <>
      <Navbar />
      <main id="main" className="main">
        <MenuSidebar
          userData={userData}
          onContentChange={handleContentChange}
          onSiteChange={handleSiteChange}
        />
        {selectedContent === 'dashboard' && <MySpaceDashboard selectedSite={selectedSite} userData={userData} />}
        {selectedContent === 'settings' && (
          <MySpaceSettings
            selectedSite={selectedSite}
            userData={userData}
            subscriptionDetails={subscriptionDetails}
            subscriptionError={subscriptionError} 
          />
        )}
        {selectedContent === 'documents' && <MySpaceDocument selectedSite={selectedSite} />}
        {selectedContent === 'help' && <MySpaceHelp selectedSite={selectedSite} />}
      </main>
    </>
  );
}

export default MySpace;
