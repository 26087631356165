import React from 'react';
import Navbar from './basic/Navbar';
import Footer from './basic/Footer';

function Experts() {

  return (
    <>
      <Navbar/>
        <section id="consulting" className="consulting custom-consulting">
            <div className="container">
              <div className="row">
                <div className="section-title">
                  <h2>Experts</h2>
                  <p>Notre entreprise accompagne nos clients avec des solutions innovantes pour optimiser leur gestion de données, garantissant une performance exceptionnelle, une sécurité maximale et une transformation numérique réussie.</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <img src='image3.jpg' alt="Experts en consultation" className="img-fluid" />
                </div>
                <div className="col-md-6">
                  <p>Texte à droite</p>
                </div>
              </div>
            </div>
        </section>
      <Footer />
    </>
  );
}

export default Experts;
