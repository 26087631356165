import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import LogoSisent from "../../assets/img/LOGO-SISENT-TRANSPARANT-BLEU.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const token = localStorage.getItem("token");
  const history = useHistory();

  const handleLogout = () => {
    localStorage.removeItem("token");
    history.push("/");
    window.location.reload();
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = (event) => {
    event.preventDefault();
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 950 && isOpen) {
        setIsOpen(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isOpen]);

  return (
    <>
      <nav className="nav">
        <input
          type="checkbox"
          id="nav-check"
          checked={isOpen}
          onChange={toggleMenu}
        />
        <div className="nav-header">
          <div className="nav-title">
            <Link to="/">
              <img
                src={LogoSisent}
                alt="Logo"
                className="navbar-brand"
                style={{ width: "150px", height: "auto" }}
              />
            </Link>
          </div>
        </div>
        <div className="nav-btn">
          <label htmlFor="nav-check">
            <span></span>
            <span></span>
            <span></span>
          </label>
        </div>

        <ul className="nav-list">
          <li>
            <Link to="/myspace" className="link-navbar">
              THEMES
            </Link>
          </li>
          <li>
            <Link to="/pricing" className="link-navbar">
              WORDPRESS
            </Link>
          </li>
          <li>
            <Link to="/experts" className="link-navbar">
              EXPERTS
            </Link>
          </li>
          <li>
            <Link to="/pricing" className="link-navbar">
              TARIFS
            </Link>
          </li>

          {!token ? (
            <>
              <li>
                <Link to="/loginpage" className="link-navbar">
                  CONNEXION
                </Link>
              </li>
              <li>
                <Link to="/pricing" className="nav-signup-btn fs-5">
                  Créer votre Site
                </Link>
              </li>
            </>
          ) : (
            <li className="nav-item dropdown d-none d-lg-block">
              <a href="/" className="nav-link px-lg-3 py-3 py-lg-4" onClick={toggleDropdown}>
                <i className="bi bi-person fs-3"></i>
              </a>
              {isDropdownOpen && (
                <ul className="dropdown-menu show" aria-labelledby="navbarDropdown">
                  <li>
                    <Link className="dropdown-item" to="/myspace">
                      MON ESPACE
                    </Link>
                  </li>
                  <li>
                    <a href="/" className="dropdown-item" onClick={handleLogout}>
                      LOGOUT
                    </a>
                  </li>
                </ul>
              )}
            </li>
          )}
        </ul>
      </nav>
      {isOpen && <div style={{ height: '120px' }}></div>}
    </>
  );
};

export default Navbar;
