import React, { useState, useEffect, useCallback } from "react";
import { FormControl, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import axios from "axios";

function ThemeSelection({ nextStep, updateSelectedTheme }) {
  const [token] = useState(localStorage.getItem("token") || "");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [themes, setThemes] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState("");
  const [displayedThemes, setDisplayedThemes] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState({
    ecommerce: false,
    blog: false,
    vitrine: false,
  });
  const [sortOrder, setSortOrder] = useState("price-asc");
  const [selectedThemeUrl, setSelectedThemeUrl] = useState("");
  const [isIframeOpen, setIframeOpen] = useState(false);
  const [isPriceDropdownOpen, setPriceDropdownOpen] = useState(false);
  const [isCategoryDropdownOpen, setCategoryDropdownOpen] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const REACT_APP_API_COMPONENT_URL = process.env.REACT_APP_API_COMPONENT_URL;

  useEffect(() => {
    setIframeOpen(!!selectedThemeUrl);
  }, [selectedThemeUrl]);

  const getSignedUrl = useCallback(async (fileName, themeName) => {
    try {
      const apiUrl =
        process.env.REACT_APP_API_COMPONENT_URL || "http://localhost:3001/api";
      const response = await axios.post(
        `${apiUrl}/themes/getimagetheme`,
        { fileName, themeName },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        }
      );
      return response.data.url;
    } catch (error) {
      console.error("Error fetching signed URL:", error);
      return "";
    }
  }, [token]);

  useEffect(() => {
    const fetchThemes = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_API_COMPONENT_URL}/themes/list`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            }
          }
        );

        const themesWithUrls = await Promise.all(
          response.data.map(async (theme) => {
            const signedUrl = await getSignedUrl(
              theme.name + "-cover.jpg",
              theme.name
            );
            return { ...theme, signedUrl };
          })
        );

        setThemes(themesWithUrls);
        setDisplayedThemes(themesWithUrls);
      } catch (error) {
        console.error("Error fetching themes:", error);
      }
    };

    fetchThemes();
  }, [getSignedUrl, token, REACT_APP_API_COMPONENT_URL]);

  const loadMoreThemes = useCallback(() => {
    if (themes.length > 0 && !isLoadingMore) {
      setIsLoadingMore(true);
      setTimeout(() => {
        setDisplayedThemes((prevThemes) => [...prevThemes, ...themes]);
        setIsLoadingMore(false);
      }, 100);
    }
  }, [themes, isLoadingMore]);

  useEffect(() => {
    const handleScroll = () => {
        const scrollableElement = document.querySelector('.addsite');
        if (scrollableElement) {
            if (scrollableElement.scrollTop + scrollableElement.clientHeight >= scrollableElement.scrollHeight - 100) {
                loadMoreThemes(); // Fonction pour charger plus de thèmes
            }
        }
    };

    const scrollableElement = document.querySelector('.addsite');
    if (scrollableElement) {
        scrollableElement.addEventListener('scroll', handleScroll);
    }
    return () => {
        if (scrollableElement) {
            scrollableElement.removeEventListener('scroll', handleScroll);
        }
    };
}, [themes, isLoadingMore, loadMoreThemes]);


    const filteredThemes = displayedThemes
    .filter(
      (theme) =>
        (theme.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          theme.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          theme.tags?.toLowerCase().includes(searchTerm.toLowerCase())) &&
        (!selectedCategories.ecommerce || theme.category === "ecommerce") &&
        (!selectedCategories.blog || theme.category === "blog") &&
        (!selectedCategories.vitrine || theme.category === "vitrine")
    )
    .sort((a, b) => {
      if (sortOrder === "price-asc") {
        return a.price - b.price;
      } else if (sortOrder === "price-desc") {
        return b.price - a.price;
      }
      return 0;
    });

    const handleThemeSelection = (theme) => {
      setIsLoading(true);
      if (theme === selectedTheme) {
        // Si on clique sur la même carte, on réinitialise l'état
        setSelectedTheme(null);
        updateSelectedTheme(null);
        setSelectedThemeUrl("");
      } else {
        setSelectedTheme(theme);
        updateSelectedTheme(theme);
        localStorage.setItem("selectedThemeId", theme.id);
        setSelectedThemeUrl(theme.urlThemes);
      }
      setIsLoading(false);
    };

  const handleSortOrderChange = (order) => {
    setSortOrder(order);
    setPriceDropdownOpen(false);
  };

  const openModal = (description) => {
    setSelectedDescription(description);
    setModalIsOpen(true);
  };

  const handleClosePreview = () => {
    setSelectedTheme(null);
    setSelectedThemeUrl("");
  };

  const handleCategoryChange = (category) => {
    setSelectedCategories((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  const toggleCategoryDropdown = () => {
    setCategoryDropdownOpen(!isCategoryDropdownOpen);
    if (!isCategoryDropdownOpen) {
      setPriceDropdownOpen(false);
    }
  };

  const togglePriceDropdown = () => {
    setPriceDropdownOpen(!isPriceDropdownOpen);
    if (!isPriceDropdownOpen) {
      setCategoryDropdownOpen(false);
    }
  };



  return (
    <>
      <div
        className={selectedTheme ? "col-md-5" : "col-md-12"}
        style={{
          display: isLoading ? "none" : "block",
        }}
      >
        <div className="container hide-scrollbar">
          <div className="row">
            {/* Titre et flèche de retour */}
            <div className="col-12 mb-2">
              <div
                className={`d-flex ${
                  selectedTheme
                    ? "flex-column"
                    : "flex-column flex-md-row mt-3 mb-2 "
                } align-items-start justify-content-between `}
              >
                <div className="d-flex align-items-center ">
                  <Link to="/">
                    <i className="bi bi-arrow-left fs-3 me-2"></i>
                  </Link>
                  <h5
                    style={{
                      fontSize:
                        window.innerWidth < 768 || selectedTheme
                          ? "20px"
                          : "30px",
                      textAlign: "left",
                      margin: 0,
                    }}
                  >
                    Sélectionnez le thème qui vous correspond
                  </h5>
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{
                    marginTop:
                      window.innerWidth < 768 || selectedTheme ? "10px" : "0",
                    width:
                      window.innerWidth < 768 || selectedTheme
                        ? "100%"
                        : "auto",
                  }}
                >
                  <Form
                    className="position-relative me-2"
                    style={{
                      width:
                        window.innerWidth < 768 || selectedTheme
                          ? "100%"
                          : "300px",
                    }}
                  >
                    <FormControl
                      type="text"
                      placeholder={
                        selectedTheme || window.innerWidth < 768
                          ? "Rechercher"
                          : "Rechercher un thème"
                      }
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      style={{ paddingRight: "40px" }}
                    />
                    <i
                      className="bi bi-search"
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        pointerEvents: "none",
                        color: "#888",
                      }}
                    ></i>
                  </Form>

                  {selectedTheme || window.innerWidth < 768 ? (
                    <button
                      className="btn btn-light"
                      onClick={() => setIsFilterOpen(!isFilterOpen)}
                      style={{ marginLeft: "auto", width: "100%" }}
                    >
                      Filter <i className="bi bi-funnel-fill ms-1"></i>
                    </button>
                  ) : (
                    <div className="d-flex">
                      <div className="position-relative me-2">
                        <button
                          className="btn btn-light"
                          onClick={toggleCategoryDropdown}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          Categories <i className="bi bi-chevron-down ms-1"></i>
                        </button>
                        {isCategoryDropdownOpen && (
                          <div
                            className="dropdown-menu show"
                            style={{
                              position: "absolute",
                              top: "100%",
                              left: "0",
                              zIndex: "10001",
                              backgroundColor: "white",
                            }}
                          >
                            <label className="dropdown-item">
                              <input
                                type="checkbox"
                                checked={selectedCategories.ecommerce}
                                onChange={() =>
                                  handleCategoryChange("ecommerce")
                                }
                                style={{ marginRight: "8px" }}
                              />
                              Ecommerce
                            </label>
                            <label className="dropdown-item">
                              <input
                                type="checkbox"
                                checked={selectedCategories.blog}
                                onChange={() => handleCategoryChange("blog")}
                                style={{ marginRight: "8px" }}
                              />
                              Blog
                            </label>
                            <label className="dropdown-item">
                              <input
                                type="checkbox"
                                checked={selectedCategories.vitrine}
                                onChange={() => handleCategoryChange("vitrine")}
                                style={{ marginRight: "8px" }}
                              />
                              Vitrine
                            </label>
                          </div>
                        )}
                      </div>
                      <div className="position-relative me-2">
                        <button
                          className="btn btn-light"
                          onClick={togglePriceDropdown}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          Prices <i className="bi bi-chevron-down ms-1"></i>
                        </button>
                        {isPriceDropdownOpen && (
                          <div
                            className="dropdown-menu show"
                            style={{
                              position: "absolute",
                              top: "100%",
                              left: "0",
                              zIndex: "1000",
                              backgroundColor: "white",
                            }}
                          >
                            <button
                              className="dropdown-item"
                              onClick={() => handleSortOrderChange("price-asc")}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <i className="bi bi-arrow-up-right me-2"></i>{" "}
                              Croissant
                            </button>
                            <button
                              className="dropdown-item"
                              onClick={() =>
                                handleSortOrderChange("price-desc")
                              }
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <i className="bi bi-arrow-down-right me-2"></i>{" "}
                              Décroissant
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {selectedTheme && (
                    <button
                      className="btn-get-startedValider"
                      onClick={() => nextStep(selectedTheme)}
                      style={{
                        marginLeft: "10px",
                      }}
                      disabled={!selectedTheme}
                    >
                      Valider
                    </button>
                  )}
                </div>
              </div>
            </div>

            {/* Affichage des filtres pour mobile */}
            {(selectedTheme || window.innerWidth < 768) && isFilterOpen && (
              <div className="filter-menu">
                <div className="position-relative me-2">
                  <button
                    className="btn btn-light"
                    onClick={() =>
                      setCategoryDropdownOpen(!isCategoryDropdownOpen)
                    }
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Categories <i className="bi bi-chevron-down ms-1"></i>
                  </button>
                  {isCategoryDropdownOpen && (
                    <div
                      className="dropdown-menu show"
                      style={{
                        position: "absolute",
                        top: "100%",
                        left: "0",
                        zIndex: "10001",
                        backgroundColor: "white",
                      }}
                    >
                      <label className="dropdown-item">
                        <input
                          type="checkbox"
                          checked={selectedCategories.ecommerce}
                          onChange={() => handleCategoryChange("ecommerce")}
                          style={{ marginRight: "8px" }}
                        />
                        Ecommerce
                      </label>
                      <label className="dropdown-item">
                        <input
                          type="checkbox"
                          checked={selectedCategories.blog}
                          onChange={() => handleCategoryChange("blog")}
                          style={{ marginRight: "8px" }}
                        />
                        Blog
                      </label>
                      <label className="dropdown-item">
                        <input
                          type="checkbox"
                          checked={selectedCategories.vitrine}
                          onChange={() => handleCategoryChange("vitrine")}
                          style={{ marginRight: "8px" }}
                        />
                        Vitrine
                      </label>
                    </div>
                  )}
                </div>
                <div className="position-relative me-2">
                  <button
                    className="btn btn-light"
                    onClick={() => setPriceDropdownOpen(!isPriceDropdownOpen)}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Prices <i className="bi bi-chevron-down ms-1"></i>
                  </button>
                  {isPriceDropdownOpen && (
                    <div
                      className="dropdown-menu show"
                      style={{
                        position: "absolute",
                        top: "100%",
                        left: "0",
                        zIndex: "1000",
                        backgroundColor: "white",
                      }}
                    >
                      <button
                        className="dropdown-item"
                        onClick={() => handleSortOrderChange("price-asc")}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <i className="bi bi-arrow-up-right me-2"></i> Croissant
                      </button>
                      <button
                        className="dropdown-item"
                        onClick={() => handleSortOrderChange("price-desc")}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <i className="bi bi-arrow-down-right me-2"></i>{" "}
                        Décroissant
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* Affichage de l'aperçu en iframe */}
            {selectedTheme && (
              <div
                className="preview-header"
                style={{
                  zIndex: isFilterOpen ? "9997" : "10000",
                  position: "absolute",
                  top: "10px",
                  right: window.innerWidth < 768 ? "auto" : "10px",
                  left: window.innerWidth < 768 ? "10px" : "auto",
                }}
              >
                <button
                  className="close-preview-btn"
                  onClick={handleClosePreview}
                  style={{
                    background: "none",
                    border: "none",
                    fontSize: "64px",
                    top: "-20px",
                    cursor: "pointer",
                    position: "fixed",
                    left: window.innerWidth <= 768 ? "10px" : "auto",
                    right: window.innerWidth > 768 ? "20px" : "auto",
                    color: "red",
                    animation: "colorAnimation 3s linear infinite",
                    zIndex: isFilterOpen ? "9998" : "10000",
                  }}
                >
                  &times;
                </button>
              </div>
            )}

            <div
              className="row gy-4 mt-0 hide-scrollbar"
              style={{
                height: "80vh",
                overflowY: "auto",
                filter: isLoading ? "blur(15px)" : "none",
                opacity: isLoading ? 0.2 : 1,
                display: isLoading ? "none" : "flex",
                transition: "all 0.5s ease",
              }}
            >
              {filteredThemes.map((theme, index) => (
                <div
                  key={index}
                  className={
                    selectedTheme
                      ? "col-12 col-md-6 mb-2 "
                      : "col-6 col-md-4 mb-3"
                  }
                  style={{
                    paddingRight:
                      window.innerWidth < 768
                        ? "0px"
                        : selectedTheme && index % 2 === 0
                        ? "10px"
                        : "15px",
                    paddingLeft:
                      window.innerWidth < 768
                        ? "0px"
                        : selectedTheme && index % 2 !== 0
                        ? "10px"
                        : "0px",
                    margin: window.innerWidth < 768 ? "10px auto" : "0 auto",
                  }}
                >
                  <div
                    onClick={() => handleThemeSelection(theme)}
                    className={`theme-card ${
                      theme === selectedTheme ? "borderAnimation" : ""
                    }`}
                    style={{
                      cursor: "pointer",
                      backgroundColor: "white",
                      width: selectedTheme ? "100%" : "100%",
                      height: selectedTheme ? "250px" : "100%",
                      marginLeft: window.innerWidth < 768 ? "10px" : 0,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      borderRadius: "2px",
                      overflow: "hidden",
                      boxSizing: "border-box",
                      transition: "all 0.5s ease",
                      border:
                        theme === selectedTheme
                          ? "8px solid #000"
                          : "1px solid #ddd",
                    }}
                  >
                    <div
                      className="theme-img"
                      style={{
                        flex: selectedTheme ? "1 1 auto" : "3",
                        overflow: "hidden",
                        margin: "0",
                        height: selectedTheme ? "100%" : "300px",
                        padding: "0",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={theme.signedUrl || "theme.jpg"}
                        alt={theme.name}
                        style={{
                          width: selectedTheme ? "100%" : "100%",
                          height: selectedTheme ? "100%" : "300px",
                          objectFit: "cover",
                          borderRadius: selectedTheme ? "0 0 0 10px" : "10px",
                          margin: "0",
                          padding: "0",
                        }}
                      />
                    </div>
                    <div
                      className="theme-header"
                      style={{
                        flex: selectedTheme ? "1 1 auto" : "initial",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        padding: "10px 15px",
                        textAlign: selectedTheme ? "left" : "center",
                      }}
                    >
                      <h5 style={{ margin: "3px", textAlign: "left" }}>
                        {theme.name}
                        <i
                          className="bi bi-info-circle ms-1"
                          onClick={(e) => {
                            e.stopPropagation();
                            openModal(theme.description);
                          }}
                        ></i>
                      </h5>
                      <h6 style={{ margin: "3px", textAlign: "right" }}>
                        {theme.price}
                      </h6>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            contentLabel="Description Modal"
          >
            <h2>Description</h2>
            <p>{selectedDescription}</p>
            <button onClick={() => setModalIsOpen(false)}>Fermer</button>
          </Modal>
        </div>
      </div>

      <div className="col-md-7">
        {selectedThemeUrl ? (
          <div
            className={`iframe-container ${isIframeOpen ? "open" : "close"}`}
            style={{
              width: window.innerWidth < 768 ? "110%" : "105%",
              height: window.innerWidth < 768 ? "100%" : "100%",
              border: "none",
              paddingRight: window.innerWidth < 768 ? "0" : "50px",
              paddingLeft: window.innerWidth < 768 ? "0" : "40px",
              position: window.innerWidth < 768 ? "fixed" : "relative",
              top: window.innerWidth < 768 ? "-50px" : "auto",
              left: window.innerWidth < 768 ? "0" : "auto",
              right: "0",
              zIndex: window.innerWidth < 768 ? "9998" : "auto",
              transition:
                window.innerWidth < 768
                  ? "none"
                  : "opacity 0.5s ease, transform 0.5s ease",
              pointerEvents: isFilterOpen ? "none" : "auto",
            }}
          >
            <iframe
              src={selectedThemeUrl}
              title={`Preview of ${
                selectedTheme ? selectedTheme.name : "selected theme"
              }`}
              onLoad={handleIframeLoad}
              style={{
                width: "100%",
                height: "100%",
                border: "none",
                display: isLoading ? "none" : "block",
              }}
            ></iframe>
          </div>
        ) : null}

        {/* Bouton Valider pour mobile */}
        {isIframeOpen && window.innerWidth < 768 && (
          <button
            className="btn-get-startedValider"
            onClick={() => nextStep(selectedTheme)}
            style={{
              position: "fixed",
              bottom: "20px",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: "10002",
              backgroundColor: " #000000",
              color: "white",
              padding: "10px 15px",
              borderRadius: "15px",
              cursor: "pointer",
              textAlign: "center",
              whiteSpace: "nowrap",
            }}
          >
            Choisir ce thème
          </button>
        )}
      </div>
    </>
  );
}

export default ThemeSelection;
