import React from 'react';
import { Container, Row, Col, Card, Button, Dropdown } from 'react-bootstrap';

function MySpaceDashboard({ userData, selectedSite }) {

    const getModifiedUrl = (url) => {
        if (url) {
            const formattedUrl = url.endsWith('.') ? url.slice(0, -1) : url;
      
            return formattedUrl;
        }
        return url;
    };

    return (
        <Container fluid className="section dashboard">
            <Row>
                <Col lg={12}>
                    <Row className="equal-height">
                        <Col xl={8} md={8} className="mb-4 d-flex align-items-stretch">
                            <Card className="info-card url-card h-100">
                                <Card.Body>
                                    <Row>
                                        {selectedSite && (
                                            <>
                                                <Card.Title>Url <span>| {selectedSite.name}</span></Card.Title>
                                                <div className="d-flex align-items-center">
                                                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center icon-black">
                                                        <i className="bi bi-link-45deg"></i>
                                                    </div>
                                                    <div className="ps-3">
                                                        <a href={getModifiedUrl(selectedSite.url)} target="_blank" rel="noopener noreferrer" className="url-black">
                                                            <h6>{getModifiedUrl(selectedSite.url)}</h6>
                                                        </a>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col xl={4} md={8} className="mb-4 d-flex align-items-stretch">
                            <Card className="info-card sales-card h-100">
                                <Card.Body>
                                    <Row>
                                        <Card.Title>Etat <span>| Disponible</span></Card.Title>
                                        <div className="d-flex align-items-center">
                                            <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                <i className="bi bi-bookmark-check-fill"></i>
                                            </div>
                                            <div className="ps-2">
                                                <span className="text-muted small pt-1">Aucun problème à signaler</span>
                                            </div>
                                        </div>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col xl={8} md={8}>
                            <Card className="info-card theme-card">
                                <Card.Body>
                                    <Row>
                                        {selectedSite && (
                                            <Row>
                                                <Col md={4}>
                                                    <div>
                                                        <center>
                                                            <h5>{selectedSite.themeName}</h5>
                                                            version 1.0.0
                                                            <br /><br />
                                                            <Button variant="warning" className="mr-2 mb-2">
                                                                <i className="bi bi-arrow-bar-up fs-4"></i>
                                                            </Button>
                                                        </center>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div>
                                                        <center>
                                                            <h5>Sécurité activée</h5>
                                                            <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                                <i className="bi bi-shield-lock-fill"></i>
                                                            </div>
                                                            <br />
                                                        </center>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div>
                                                        <center>
                                                            <h5>Visites / total</h5>
                                                            <br />
                                                            <h3>1.200</h3>
                                                        </center>
                                                    </div>
                                                </Col>
                                            </Row>
                                        )}
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col xl={4} md={4}>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <div className="filter">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="link" className="icon">
                                                    <i className="bi bi-three-dots"></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Header>Filter</Dropdown.Header>
                                                    <Dropdown.Item onClick={() => { /* handle Today filter */ }}>Today</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => { /* handle This Month filter */ }}>This Month</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => { /* handle This Year filter */ }}>This Year</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <Card.Title>News & Updates <span>| Today</span></Card.Title>
                                        <div className="news">
                                            <div className="post-item clearfix">
                                                <h4>
                                                    <Button variant="link" onClick={() => { /* handle click */ }}>
                                                        Nihil blanditiis at in nihil autem
                                                    </Button>
                                                </h4>
                                                <p>Sit recusandae non aspernatur laboriosam. Quia enim eligendi sed ut harum...</p>
                                            </div>
                                            <div className="post-item clearfix">
                                                <h4>
                                                    <Button variant="link" onClick={() => { /* handle click */ }}>
                                                        Quidem autem et impedit
                                                    </Button>
                                                </h4>
                                                <p>Illo nemo neque maiores vitae officiis cum eum turos elan dries werona nande...</p>
                                            </div>
                                            <div className="post-item clearfix">
                                                <h4>
                                                    <Button variant="link" onClick={() => { /* handle click */ }}>
                                                        Id quia et et ut maxime similique occaecati ut
                                                    </Button>
                                                </h4>
                                                <p>Fugiat voluptas vero eaque accusantium eos. Consequuntur sed ipsam et totam...</p>
                                            </div>
                                            <div className="post-item clearfix">
                                                <h4>
                                                    <Button variant="link" onClick={() => { /* handle click */ }}>
                                                        Laborum corporis quo dara net para
                                                    </Button>
                                                </h4>
                                                <p>Qui enim quia optio. Eligendi aut asperiores enim repellendus vel rerum cuder...</p>
                                            </div>
                                            <div className="post-item clearfix">
                                                <h4>
                                                    <Button variant="link" onClick={() => { /* handle click */ }}>
                                                        Et dolores corrupti quae illo quod dolor
                                                    </Button>
                                                </h4>
                                                <p>Odit ut eveniet modi reiciendis. Atque cupiditate libero beatae dignissimos eius...</p>
                                            </div>
                                        </div>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default MySpaceDashboard;
