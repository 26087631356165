import React, { useState, useEffect } from 'react';
import { InputGroup, FormControl, Form, Button, ProgressBar, Row } from 'react-bootstrap';
import StripePricingTable from '../../components/billing/StripePricingTable';
import ThemeSelection from './ThemeSelection';
import UserAuthentificate from './UserAuthentificate';
import { jwtDecode } from "jwt-decode";
import '../../assets/css/process-add-site.css';
import axios from 'axios';

function ProcessAddSite() {
  const [token] = useState(localStorage.getItem('token') || '');
  const [step, setStep] = useState(1);
  const [selectedTheme, setSelectedTheme] = useState("");
  const [username, setUsername] = useState(null);
  const [nameSite, setNameSite] = useState('');
  const [urlSite, setUrlSite] = useState('');
  const [urlSiteSelected, setUrlSiteSelected] = useState('');
  const [priceDomaine, setPriceDomaine] = useState('');
  const [curlResponse, setCurlResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [priceIds, setPriceIds] = useState([]); // Nouvelle variable d'état pour les IDs de prix
  const REACT_APP_API_GCP_URL = process.env.REACT_APP_API_GCP_URL;
  const REACT_APP_API_STRIPE_URL = process.env.REACT_APP_API_STRIPE_URL;

  const nextStep = (theme, username) => {
    setSelectedTheme(theme);
    setUsername(username);
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const updateSelectedTheme = (theme) => {
    setSelectedTheme(theme);
  };

  const searchDomainRequest = async () => {
    try {
      setIsLoading(true);
      setShowError(false);
      const response = await axios.post(`${REACT_APP_API_GCP_URL}/gcp/getSearchDomains`, { domain: urlSite }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      });
      setCurlResponse(response.data);
    } catch (error) {
      if (error.response && error.response.status === 500) {
        setShowError(true);
      }
      console.error('Error executing curl request:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectDomain = (selectedDomain, customPrice) => {
    setUrlSiteSelected(selectedDomain);
    setPriceDomaine(customPrice);
  };

  useEffect(() => {
    const storedTheme = localStorage.getItem('selectedTheme');
    if (storedTheme) {
      setSelectedTheme(storedTheme);
    }

    if (token) {
      const decodedToken = jwtDecode(token);
      setUsername(decodedToken.username);
    }
  }, [token]);

  useEffect(() => {
    if (step === 3) {
      const fetchProducts = async () => {
        try {
          const response = await axios.get(`${REACT_APP_API_STRIPE_URL}/stripe/getProducts`, {
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          });
          
          const priceIdList = [];
          response.data.forEach(p => {
            if (p.default_price_unit === priceDomaine && p.name.startsWith('Domaine')) {
              priceIdList.push(p.default_price);
            }
            if (p.name.includes(selectedTheme.name)) {
              priceIdList.push(p.default_price);
            }
          });
          setPriceIds(priceIdList);
        } catch (error) {
          console.log(error.message);
        }
      };

      fetchProducts();
    }
  }, [step, priceDomaine, token, REACT_APP_API_STRIPE_URL, selectedTheme]);

  const getCustomPrice = (units) => {
    if (units === 0) {
      return '0.00';
    } else if (units < 20) {
      return '3.99';
    } else if (units >= 20 && units < 50) {
      return '5.99';
    } else if (units >= 50 && units < 80) {
      return '9.99';
    } else if (units >= 80 && units <= 100) {
      return '14.99';
    } else {
      return null;
    }
  };

  return (
    <section id="addsite" className="addsite">
      <div className="container-fluid full-height">
        <div className="row full-height">

          {step === 1 && (
            <>
                <ThemeSelection nextStep={nextStep} updateSelectedTheme={updateSelectedTheme} />
            </>
          )}

          {step === 2 && (
            <>
              <div className="container" >
                <div className="row">
                  <ProgressBar
                    now={(step / 4) * 100}
                    label={`${step}/4`}
                    variant="success"
                    className="custom-progress-bar"
                  />
                  <div className="col-md-2"></div>
                  <div className="col-md-7">
                    <center>
                      <h2>Trouve le bon domaine qui reflète ta vision en ligne</h2>
                    </center>

                    <Form>
                      <Row className="mb-3">
                        <Form.Label>Nom de votre site</Form.Label>
                        <InputGroup>
                          <FormControl
                            type="text"
                            className="form-control"
                            id="urlSite"
                            value={nameSite}
                            onChange={(e) => {
                              setNameSite(e.target.value);
                              setUrlSite(e.target.value);
                            }}
                            required
                          />

                          <Button className="buy-btn-search" variant="outline-secondary" onClick={searchDomainRequest}>
                            <i className="bi bi-search"></i>
                          </Button>
                        </InputGroup>
                      </Row>
                      {isLoading ? (
                        <i className={`bi bi-arrow-clockwise fs-4 ${isLoading ? 'rotate-icon' : ''}`}></i>
                      ) : curlResponse && curlResponse.length > 1 ? (
                        <>
                          Veuillez sélectionner une URL disponible à partir de la liste ci-dessous
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Domaine</th>
                                <th>Prix / Mois</th>
                                <th>Disponible</th>
                              </tr>
                            </thead>
                            <tbody>
                              {[
                                curlResponse[0],
                                ...curlResponse[1]
                              ]
                                .filter(domain => domain.availability === "AVAILABLE" || domain.domainName.includes(`${nameSite}.myhappyweb.com`))
                                .slice(0, 10)
                                .map((domain) => {
                                  const customPrice = getCustomPrice(domain.yearlyPrice ? domain.yearlyPrice.units : 0);
                                  if (customPrice === null) {
                                    return null;
                                  }
                                  return (
                                    <tr
                                      key={domain.domainName}
                                      onClick={domain.availability === 'AVAILABLE' ? () => handleSelectDomain(domain.domainName, customPrice) : null}
                                      className={domain.domainName === urlSiteSelected ? 'table-primary' : ''}
                                      style={{ cursor: domain.availability === 'AVAILABLE' ? 'pointer' : 'default' }}
                                    >
                                      <td>{domain.domainName}</td>
                                      <td>
                                        {customPrice ? `+ ${customPrice} €` : '0,00 €'}
                                      </td>
                                      <td>
                                        {domain.availability === 'AVAILABLE' ? <i className="bi bi-check-circle-fill green-button"></i> : <i className="bi bi-x-circle-fill"></i>}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </>
                      ) : (
                        showError && <div style={{ color: 'red' }}>Une erreur s'est produite lors de la recherche des domaines.</div>
                      )}
                      <Button className="buy-btn" variant="secondary" onClick={prevStep}>Précédent</Button>{' '}
                      <Button
                        className="buy-btn"
                        variant="primary"
                        onClick={() => {
                          nextStep(selectedTheme);
                        }}
                        disabled={urlSiteSelected === ''}
                      >
                        Suivant
                      </Button>
                    </Form>
                  </div>
                  <div className="col-md-2"></div>
                </div>
              </div>
            </>
          )}
          {step === 3 && (
            <>
              <div className="container">
                <div className="row">
                  <ProgressBar
                    now={(step / 4) * 100}
                    label={`${step}/4`}
                    variant="success"
                    className="custom-progress-bar"
                  />
                  <div className="col-md-3"></div>
                  <div className="col-md-5">

                    <UserAuthentificate nextStep={nextStep} prevStep={prevStep} selectedTheme={selectedTheme} />
                  </div>
                  <div className="col-md-3"></div>
                </div>
              </div>
            </>
          )}

          {step === 4 && (
            <>
              <div className="container">
                <div className="row">
                  <ProgressBar
                    now={(step / 4) * 100}
                    label={`${step}/4`}
                    variant="success"
                    className="custom-progress-bar"
                  />
                    {urlSiteSelected} 

                  <StripePricingTable 
                    userName={username} 
                    theme={selectedTheme} 
                    urlSite={urlSiteSelected} 
                    nameSite={nameSite} 
                    priceIds={priceIds}
                    prevStep={prevStep} 
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default ProcessAddSite;
