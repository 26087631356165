import React, { useState } from 'react';
import Navbar from './basic/Navbar';
import Footer from './basic/Footer';
import { Form, Button, Row, Col } from 'react-bootstrap';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    prenom: '',
    entreprise: '',
    email: '',
    telephone: '',
    message: ''
  });

  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const validateForm = () => {
    const errors = {};
    if (!formData.name) {
      errors.name = 'Veuillez entrer votre nom';
    }
    if (!formData.prenom) {
      errors.prenom = 'Veuillez entrer votre prénom';
    }
    if (!formData.email) {
      errors.email = 'Veuillez entrer votre adresse email';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Veuillez entrer une adresse email valide';
    }
    if (!formData.message) {
      errors.message = 'Veuillez entrer un message';
    } else if (formData.message.length < 15 || formData.message.length > 500) {
      errors.message = 'Le message doit contenir entre 15 et 500 caractères';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      // Envoyer le formulaire
      setSubmitting(true);
      // Ici, vous pouvez envoyer les données du formulaire à votre serveur ou effectuer toute autre action nécessaire
      console.log(formData);
      setSubmitting(false);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  return (
    <>
      <Navbar/>
      <section id="contact" className="contact custom-contact">
        <div className="container">
          <div className="row">
            <div className="section-title">
                <h2>Contact</h2>
                <p>Travaillons ensemble ! Dites-nous en plus sur vous</p>
              </div>

            <div className="col"></div>
            <div className="col-6">

              <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="name">
                    <Form.Label>Nom</Form.Label>
                    <Form.Control type="text" value={formData.name} onChange={handleChange} isInvalid={!!errors.name} />
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} controlId="prenom">
                    <Form.Label>Prénom</Form.Label>
                    <Form.Control type="text" value={formData.prenom} onChange={handleChange} isInvalid={!!errors.prenom} />
                    <Form.Control.Feedback type="invalid">{errors.prenom}</Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" value={formData.email} onChange={handleChange} isInvalid={!!errors.email} />
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} controlId="telephone">
                    <Form.Label>Téléphone</Form.Label>
                    <Form.Control type="tel" value={formData.telephone} onChange={handleChange} />
                  </Form.Group>
                </Row>
                <Form.Group className="mb-3" controlId="entreprise">
                  <Form.Label>Entreprise</Form.Label>
                  <Form.Control type="text" value={formData.entreprise} onChange={handleChange} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="message">
                  <Form.Label>Message</Form.Label>
                  <Form.Control as="textarea" rows={5} value={formData.message} onChange={handleChange} isInvalid={!!errors.message} />
                  <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
                </Form.Group>
                <center>
                  <Button className="buy-btn" type="submit" disabled={submitting}>
                    {submitting ? 'Envoi en cours...' : 'Envoyer'}
                  </Button>
                </center>
              </Form>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Contact;
